import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as cx from "classnames/bind";
import { Icon } from "components";
import s from "styles/Popup.module.scss";

const Popup = ({ show, onClose, className, children, title, ...props }) => {
  const [isShown, setIsShown] = useState(false);

  const showModal = () => {
    setIsShown(true);
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={cx(s.PopupOverlay, className)}>
      <div className={cx(s.Popup, className)}>
        <div className={cx("PopupHeader", s.PopupHeader)}>
          {title && <div className={cx(s.PopupTitle)}>{title}</div>}
          <a href="#" onClick={handleCloseClick}>
            <img alt="" src="/img/icons/close.svg" />
          </a>
        </div>
        <div className={cx(s.PopupBody)}>{children}</div>
      </div>
    </div>
  );
};

export default Popup;
