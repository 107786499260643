import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as cx from "classnames/bind";
import { Icon } from "components";
import s from "styles/Modal.module.scss";

const Modal = ({ show, onClose, className, children, title, mobileBottom }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const modalContent = show ? (
    <div className={cx(s.Modal)}>
      <div className={cx(s.ModalOverlay)} onClick={handleCloseClick}></div>
      <div
        className={cx(
          s.ModalContent,
          mobileBottom ? s.mobileBottom : "",
          className,
        )}
      >
        <div className={cx("ModalHeader", s.ModalHeader)}>
          {title && <div className={cx(s.ModalTitle)}>{title}</div>}
          <span onClick={handleCloseClick}>
            <img alt="" src="/img/icons/close.svg" />
          </span>
        </div>
        <div className={cx(s.ModalBody)}>
          <div className={cx(s.ModalBodyContent)}>{children}</div>
        </div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root"),
    );
  } else {
    return null;
  }
};

export default Modal;
