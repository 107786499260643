import * as cx from "classnames/bind";
import s from "styles/Input.module.scss";

import { InfoPop } from "components";
import InputContainer from "components/atoms/Input/InputContainer";

const Input = ({ name, type, value, placeholder, field, error, ...props }) => {
  return (
    <InputContainer {...props} {...field}>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={name}
        value={value}
        {...field}
        {...props}
      />
    </InputContainer>
  );
};

export default Input;
