import { GraphQLClient } from "graphql-request";
import { getSession, useSession } from "next-auth/react";
import { getServerSession } from "next-auth";
import { authOptions } from "pages/api/auth/[...nextauth]";
import useSWR from "swr";
import store from "store2";

export const extractCocktails = (obj, cocktails = []) => {
  if (obj) {
    cocktails = cocktails.concat(obj.cocktails || []);
    cocktails = extractCocktails(obj.parent, cocktails);
  }
  return cocktails;
};

export const uniqObjArrayOld = (array) => [
  ...new Map(array.map((o) => [o.id, o])).values(),
];

export const mergeByProperty = (target, source, prop) => {
  source.forEach((sourceElement) => {
    let targetElement = target.find((targetElement) => {
      return sourceElement[prop] === targetElement[prop];
    });
    targetElement
      ? Object.assign(targetElement, sourceElement)
      : target.push(sourceElement);
  });
  return source;
};

export const insert = (arr, index, newItem) => {
  let tmp = [...arr];
  tmp.splice(index, 1, newItem);
  return tmp;
};
const uniqObjArray = (array) => {
  return array.filter(
    (item, ix, self) =>
      self.findIndex((f) =>
        Object.keys(item).every((k) => f[k] === item[k]),
      ) === ix,
  );
};
const combineIngredients = (ingredients) => {
  return JSON.parse(JSON.stringify(ingredients)).reduce((acc, i) => {
    const existing = acc.find((other) => other.id === i.id);
    if (existing) {
      const measurement = existing.measurements[0] || {};
      if (measurement.metric_amount) {
        if (!isNaN(parseFloat(measurement.metric_amount))) {
          measurement.metric_amount = (
            parseFloat(measurement.metric_amount) +
            parseFloat(
              (i.measurements[0] || { metric_amount: 0 }).metric_amount,
            )
          ).toString();
        }
        if (!isNaN(parseFloat(measurement.imperial_amount))) {
          measurement.imperial_amount = (
            parseFloat(measurement.imperial_amount) +
            parseFloat(
              (i.measurements[0] || { imperial_amount: 0 }).imperial_amount,
            )
          ).toString();
        }
      }
    } else {
      acc.push(i);
    }
    return acc;
  }, []);
};

const EMPTY_PNG =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII= ";

const imageUrl = (imageId, fallback) => {
  const id = (imageId || {}).id || imageId;
  return typeof id === "string" && id != "undefined"
    ? process.env.NEXT_PUBLIC_S3_BUCKET + id + "?"
    : fallback || EMPTY_PNG;
};

const hasuraHeadersFromSession = (session) => {
  return session?.token && session?.user
    ? {
        Authorization: `Bearer ${session.token}`,
        "X-Hasura-Role": session.user.role,
        "X-Hasura-User-Id": session.user.id,
      }
    : {};
};

const withAuthenticatedOrRedirect = async (
  context,
  fn = async () => {},
  { roles, redirectTo } = {},
) => {
  const session = await getServerSession(context.req, context.res, authOptions);
  const isUser = !!session?.user;

  const redirect = {
    redirect: {
      permanent: false,
      destination: redirectTo || "/login",
    },
  };

  if (!isUser) {
    return redirect;
  } else if (roles && !roles.includes(session.user.role)) {
    return redirect;
  }

  // Returned by default, when `fn` is undefined
  const headers = hasuraHeadersFromSession(session);
  const defaultResponse = { props: { session, headers } };

  try {
    const data = (await fn({ ...context, session, headers, redirect })) || {};
    const response = {
      ...defaultResponse,
      ...(data.props ? data : {}),
      ...{ props: { ...defaultResponse.props, ...(data.props || data) } },
    };
    return response;
  } catch (exception) {
    /*
    if (process.env.NODE_ENV !== "development") {
      return redirect;
    }
    */
    throw exception;
  }
};

const gqlClient = new GraphQLClient(process.env.NEXT_PUBLIC_GRAPHQL_URL);
const fetcher = (query, ...args) => gqlClient.request(query, ...args);

function useGQLSWR(query, variables, _headers) {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const user = !loading && (session || {}).user;
  const headers = { ...(_headers || {}), ...hasuraHeadersFromSession(session) };

  return useSWR(query, (query) => fetcher(query, variables, headers));
}

const objectToFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

const updateUserSession = async (data) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_REST_API_URL}session/`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-Session-Id": store.get("session_id"),
      },
      body: JSON.stringify(data),
      credentials: "include",
    },
  );
  const session = await response.json();
  store.set(
    "session_id",
    response.headers.get("x-session-id") || session.session_id,
  );
  return session;
};

const updateUser = async (data, token) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_REST_API_URL}update`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
      credentials: "include",
    },
  );
  return await response.json();
};
const checkIfEmailExists = async (email) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_REST_API_URL}email_exists`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
      credentials: "include",
    },
  );
  return await response.json();
};

const sortIngredients = (ingredients) =>
  ingredients
    .filter((i) => !i.parent?.id)
    .map((parent) => {
      return [
        parent,
        ingredients.filter((i) => {
          return (
            i.parent &&
            (i.parent.id === parent.id ||
              i.parent?.parent?.id === parent.id ||
              i.parent?.parent?.parent?.id === parent.id)
          );
        }),
      ];
    })
    .flat(2);

export const translate = (object, prop, locale = "en") => {
  return ((object?.translations || {})[locale] || {})[prop] || object?.[prop];
};

export const isPromise = (value) =>
  Boolean(value && typeof value.then === "function");

export const reindexAlgolia = async (id, type, headers) => {
  const url = `${process.env.NEXT_PUBLIC_REST_API_URL}algolia/index_${type}/${id}`;
  return await fetch(url, {
    method: "POST",
    headers,
  });
};
export {
  fetcher,
  gqlClient,
  hasuraHeadersFromSession,
  imageUrl,
  objectToFormData,
  uniqObjArray,
  updateUser,
  updateUserSession,
  useGQLSWR,
  withAuthenticatedOrRedirect,
  checkIfEmailExists,
  combineIngredients,
  sortIngredients,
};
