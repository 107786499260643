import * as cx from "classnames/bind";
import { Button, Icon } from "components";
import { useRecipeLists } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import s from "styles/Modal.module.scss";
import { updateUserSession } from "utils";
import { useTranslation } from "next-i18next";

const ShareModal = ({ show, onClose, shareTitle, shareUrl, className }) => {
  const { t } = useTranslation();
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const modalContent = show ? (
    <div className={cx(s.Modal, s.CreateListModal)}>
      <div className={cx(s.ModalOverlay)} onClick={handleCloseClick}></div>
      <div className={cx(s.ModalContent, className)}>
        <div className={cx("ModalHeader", s.ModalHeader)}>
          <div className={cx(s.ModalTitle)}>{t("Share your menu")}</div>
          <a href="#" onClick={handleCloseClick}>
            <img alt="" src="/img/icons/close.svg" />
          </a>
        </div>
        <div className={cx(s.ModalBody)}>
          <div className={cx("flex", s.shareActions)}>
            <a
              className="shareBtn"
              id="shareBtnSMS"
              href={`sms://?&body=${shareTitle} ${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share with SMS"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M448-.0004H64c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.98c0 9.75 11.25 15.45 19.12 9.7L304 415.1H448c35.25 0 64-28.75 64-63.1V63.1C512 28.75 483.3-.0004 448-.0004zM167.3 239.9C163.9 259.1 146.3 272 121.1 272c-4.031 0-8.25-.3125-12.59-1C101.1 269.8 92.81 266.8 85.5 264.1c-8.312-3-14.06-12.66-11.09-20.97S85 229.1 93.38 232.9c6.979 2.498 14.53 5.449 20.88 6.438C125.7 241.1 135 239 135.8 234.4c1.053-5.912-10.84-8.396-24.56-12.34c-12.12-3.531-44.28-12.97-38.63-46c4.062-23.38 27.31-35.91 58-31.09c5.906 .9062 12.44 2.844 18.59 4.969c8.344 2.875 12.78 12 9.906 20.34C156.3 178.7 147.2 183.1 138.8 180.2c-4.344-1.5-8.938-2.938-13.09-3.594c-11.22-1.656-20.72 .4062-21.5 4.906C103.2 187.2 113.6 189.5 124.4 192.6C141.4 197.5 173.1 206.5 167.3 239.9zM320 256c0 8.844-7.156 16-16 16S288 264.8 288 256V208l-19.19 25.59c-6.062 8.062-19.56 8.062-25.62 0L224 208V256c0 8.844-7.156 16-16 16S192 264.8 192 256V160c0-6.875 4.406-13 10.94-15.19c6.5-2.094 13.72 .0625 17.88 5.594L256 197.3l35.19-46.94c4.156-5.531 11.41-7.656 17.88-5.594C315.6 147 320 153.1 320 160V256zM439.3 239.9C435.9 259.1 418.3 272 393.1 272c-4.031 0-8.25-.3125-12.59-1c-8.25-1.25-16.56-4.25-23.88-6.906c-8.312-3-14.06-12.66-11.09-20.97s10.59-13.16 18.97-10.19c6.979 2.498 14.53 5.449 20.88 6.438c11.44 1.719 20.78-.375 21.56-4.938c1.053-5.912-10.84-8.396-24.56-12.34c-12.12-3.531-44.28-12.97-38.63-46c4.031-23.38 27.25-35.91 58-31.09c5.906 .9062 12.44 2.844 18.59 4.969c8.344 2.875 12.78 12 9.906 20.34c-2.875 8.344-11.94 12.81-20.34 9.906c-4.344-1.5-8.938-2.938-13.09-3.594c-11.19-1.656-20.72 .4062-21.5 4.906C375.2 187.2 385.6 189.5 396.4 192.6C413.4 197.5 445.1 206.5 439.3 239.9z"></path>
              </svg>
            </a>
            <a
              className="shareBtn"
              id="shareBtnFB"
              href={`https://facebook.com/sharer/sharer.php?u=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share on Facebook"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"></path>
              </svg>
            </a>
            <a
              className="shareBtn"
              id="shareBtnTwitter"
              href={`https://twitter.com/intent/tweet/?text=${shareTitle}&url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share on Twitter"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"></path>
              </svg>
            </a>
            <a
              className="shareBtn"
              id="shareBtnMail"
              href={`mailto:?subject=${shareTitle}&body=${shareUrl}`}
              target="_self"
              rel="noopener"
              aria-label="Share by Email"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z"></path>
              </svg>
            </a>
            <a
              className="shareBtn"
              id="shareBtnPin"
              href={`https://pinterest.com/pin/create/button/?desscription=${shareTitle}&url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share on Pinterest"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12.14.5C5.86.5 2.7 5 2.7 8.75c0 2.27.86 4.3 2.7 5.05.3.12.57 0 .66-.33l.27-1.06c.1-.32.06-.44-.2-.73-.52-.62-.86-1.44-.86-2.6 0-3.33 2.5-6.32 6.5-6.32 3.55 0 5.5 2.17 5.5 5.07 0 3.8-1.7 7.02-4.2 7.02-1.37 0-2.4-1.14-2.07-2.54.4-1.68 1.16-3.48 1.16-4.7 0-1.07-.58-1.98-1.78-1.98-1.4 0-2.55 1.47-2.55 3.42 0 1.25.43 2.1.43 2.1l-1.7 7.2c-.5 2.13-.08 4.75-.04 5 .02.17.22.2.3.1.14-.18 1.82-2.26 2.4-4.33.16-.58.93-3.63.93-3.63.45.88 1.8 1.65 3.22 1.65 4.25 0 7.13-3.87 7.13-9.05C20.5 4.15 17.18.5 12.14.5z"></path>
              </svg>
            </a>
            <a
              className="shareBtn"
              id="shareBtnWA"
              href={`whatsapp://send?text=${shareTitle}&nbsp;-&nbsp;${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share on Whatsapp"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"></path>
              </svg>
            </a>
          </div>
          <div className="inputContainer">
            <input
              className={cx(s.shareLinkUrl)}
              value={shareUrl}
              type="text"
              disabled="disabled"
              placeholder="Menu URL"
              style={{ cursor: "text" }}
            />
          </div>
          <div className="flex">
            <Button
              type="submit"
              className="Primary Rad flex-grow mb0"
              title="Copy URL"
              onClick={() => navigator.clipboard.writeText(shareUrl)}
            >
              {t("Copy URL")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root"),
    );
  } else {
    return null;
  }
};

export default ShareModal;
