import axios from "axios";
import { useState } from "react";
import { hasuraHeadersFromSession } from "utils";
import { useSession } from "hooks";

export const useUploadForm = (url, headers) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { data: session, status } = useSession();

  const uploadForm = async (formData) => {
    setIsLoading(true);
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...hasuraHeadersFromSession(session),
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 50;
        setProgress(progress);
      },
      onDownloadProgress: (progressEvent) => {
        const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
        setProgress(progress);
      },
    });
    setIsSuccess(true);
    setIsLoading(false);
    return response.data;
  };

  return { uploadForm, isSuccess, isLoading, progress };
};
