import Link from "next/link";
import Image from "next/image";
import * as cx from "classnames/bind";
import { ScrollX } from "components";
import s from "styles/Z2H.module.scss";
import g from "styles/Gallery.module.scss";
import { useTranslation } from "next-i18next";

export default function Z2HMenu({ title }) {
  const { t } = useTranslation();

  return (
    <div className={cx(s.PolicyHeader)}>
      <ul className="flex space-between hidden">
        <li>
          <Link href="/z2h/bar-kit">{t("Bar kit")}</Link>
        </li>
        <li>
          <Link href="/z2h/shaker">{t("Shaker")}</Link>
        </li>
        <li>
          <Link href="/z2h/jigger">{t("Jigger")}</Link>
        </li>
        <li>
          <Link href="/z2h/muddler">{t("Muddler")}</Link>
        </li>
        <li>
          <Link href="/z2h/citrus-juicer">{t("Citrus juicer")}</Link>
        </li>
        <li>
          <Link href="/z2h/fine-strainer">{t("Fine strainer")}</Link>
        </li>
        <li>
          <Link href="/z2h/speed-pourer">{t("Speed pourer")}</Link>
        </li>
        <li>
          <Link href="/z2h/knife">{t("Knife")}</Link>
        </li>
        <li>
          <Link href="/z2h/julep-strainer">{t("Julep strainer")}</Link>
        </li>
        <li>
          <Link href="/z2h/fruit-zezter">{t("Fruit zezter")}</Link>
        </li>
        <li>
          <Link href="/z2h/bar-spoon">{t("Bar spoon")}</Link>
        </li>
        <li>
          <Link href="/z2h/stirring-glass">{t("Stirring glass")}</Link>
        </li>
        <li>
          <Link href="/z2h/ice-scoop">{t("Ice scoop")}</Link>
        </li>
        <li>
          <Link href="/z2h/hawthorne-strainer">{t("Hawthorne strainer")}</Link>
        </li>
        <li>
          <Link href="/z2h/bottle-opener">{t("Bottle opener")}</Link>
        </li>
      </ul>

      <ScrollX
        className={cx(
          "GalleryFeed",
          g.GalleryFeed,
          g.GalleryFeedMenu,
          s.Z2HFeed,
        )}
      >
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/bar-kit">
            <Image
              src="/img/slides/tools/kit3.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Bar kit")}</span>
          </Link>
        </div>

        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/shaker">
            <Image
              src="/img/slides/tools/shaker.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Shaker")}</span>
          </Link>
        </div>

        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/jigger">
            <Image
              src="/img/slides/tools/jigger.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Jigger")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/muddler">
            <Image
              src="/img/slides/tools/muddler.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Muddler")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/citrus-juicer">
            <Image
              src="/img/slides/tools/mexican_elbow_juicer.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Citrus juicer")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/fine-strainer">
            <Image
              src="/img/slides/tools/fine_strainer.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Fine strainer")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/speed-pourer">
            <Image
              src="/img/slides/tools/speed_pourer.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Speed pourer")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/knife">
            <Image
              src="/img/slides/tools/knife.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Knife")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/julep-strainer">
            <Image
              src="/img/slides/tools/julep_strainer.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Julep strainer")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/fruit-zester">
            <Image
              src="/img/slides/tools/fruit_zester.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Fruit zester")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/bar-spoon">
            <Image
              src="/img/slides/tools/bar_spoon.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Bar spoon")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/stirring-glass">
            <Image
              src="/img/slides/tools/mixing_glass.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Stirring glass")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/ice-scoop">
            <Image
              src="/img/slides/tools/ice_scoop.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Ice scoop")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/hawthorne-strainer">
            <Image
              src="/img/slides/tools/hawthorne_strainer.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Hawthorne strainer")}</span>
          </Link>
        </div>
        <div
          className={cx("GalleryFeedItem rowItemScroll__M", g.GalleryFeedItem)}
        >
          <Link href="/z2h/bottle-opener">
            <Image
              src="/img/slides/tools/bottle_opener.jpeg"
              layout="responsive"
              width={768}
              height={960}
              objectFit="cover"
              itemProp="image"
            />
            <span>{t("Bottle opener")}</span>
          </Link>
        </div>
      </ScrollX>
      <h1 className={cx(s.Z2HTitle, "Heading__main")}>{title}</h1>
    </div>
  );
}
