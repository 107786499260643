import * as cx from "classnames/bind";
import r from "styles/reviewStars.module.scss";
const Stars = ({ score = 0, className = "", scoreCount, ...props }) => (
  <>
    <div className={cx(r.starHolder, className)} ref={props.holderRef}>
      <div className={cx(r.emptyStars)}>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z" />
          </svg>
        </span>
      </div>
      <div
        className={cx(r.fullStars)}
        style={{
          width: `calc((${score} / 5) * 100%)`,
        }}
      >
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9,435.9l-137.8,73.2c-7.2,4.3-17,3.6-24.5-1.7c-7.4-5.3-11.1-14.5-10.5-23.5l27.1-155.5L31.1,218.2c-6.5-6.3-8.8-15.8-5.9-24.5c2.8-8.6,10.3-14.9,19.3-16.2l153.2-22.7l68.6-141.3C270.4,5.2,278.7,0,287.9,0c9.2,0,17.6,5.2,21.6,13.5l68.6,141.3l153.3,22.7c9,1.3,16.4,7.6,19.3,16.2c2.8,8.7,0.5,18.2-5.9,24.5L433.6,328.4l26.3,155.5c1.5,9-2.2,18.2-9.7,23.5c-7.4,5.3-18.1,6-25.3,1.7L287.9,435.9z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9,435.9l-137.8,73.2c-7.2,4.3-17,3.6-24.5-1.7c-7.4-5.3-11.1-14.5-10.5-23.5l27.1-155.5L31.1,218.2c-6.5-6.3-8.8-15.8-5.9-24.5c2.8-8.6,10.3-14.9,19.3-16.2l153.2-22.7l68.6-141.3C270.4,5.2,278.7,0,287.9,0c9.2,0,17.6,5.2,21.6,13.5l68.6,141.3l153.3,22.7c9,1.3,16.4,7.6,19.3,16.2c2.8,8.7,0.5,18.2-5.9,24.5L433.6,328.4l26.3,155.5c1.5,9-2.2,18.2-9.7,23.5c-7.4,5.3-18.1,6-25.3,1.7L287.9,435.9z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9,435.9l-137.8,73.2c-7.2,4.3-17,3.6-24.5-1.7c-7.4-5.3-11.1-14.5-10.5-23.5l27.1-155.5L31.1,218.2c-6.5-6.3-8.8-15.8-5.9-24.5c2.8-8.6,10.3-14.9,19.3-16.2l153.2-22.7l68.6-141.3C270.4,5.2,278.7,0,287.9,0c9.2,0,17.6,5.2,21.6,13.5l68.6,141.3l153.3,22.7c9,1.3,16.4,7.6,19.3,16.2c2.8,8.7,0.5,18.2-5.9,24.5L433.6,328.4l26.3,155.5c1.5,9-2.2,18.2-9.7,23.5c-7.4,5.3-18.1,6-25.3,1.7L287.9,435.9z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9,435.9l-137.8,73.2c-7.2,4.3-17,3.6-24.5-1.7c-7.4-5.3-11.1-14.5-10.5-23.5l27.1-155.5L31.1,218.2c-6.5-6.3-8.8-15.8-5.9-24.5c2.8-8.6,10.3-14.9,19.3-16.2l153.2-22.7l68.6-141.3C270.4,5.2,278.7,0,287.9,0c9.2,0,17.6,5.2,21.6,13.5l68.6,141.3l153.3,22.7c9,1.3,16.4,7.6,19.3,16.2c2.8,8.7,0.5,18.2-5.9,24.5L433.6,328.4l26.3,155.5c1.5,9-2.2,18.2-9.7,23.5c-7.4,5.3-18.1,6-25.3,1.7L287.9,435.9z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M287.9,435.9l-137.8,73.2c-7.2,4.3-17,3.6-24.5-1.7c-7.4-5.3-11.1-14.5-10.5-23.5l27.1-155.5L31.1,218.2c-6.5-6.3-8.8-15.8-5.9-24.5c2.8-8.6,10.3-14.9,19.3-16.2l153.2-22.7l68.6-141.3C270.4,5.2,278.7,0,287.9,0c9.2,0,17.6,5.2,21.6,13.5l68.6,141.3l153.3,22.7c9,1.3,16.4,7.6,19.3,16.2c2.8,8.7,0.5,18.2-5.9,24.5L433.6,328.4l26.3,155.5c1.5,9-2.2,18.2-9.7,23.5c-7.4,5.3-18.1,6-25.3,1.7L287.9,435.9z" />
          </svg>
        </span>
      </div>
    </div>
    {scoreCount && <div className={cx(r.starCount)}>{score}</div>}
  </>
);

export default Stars;
