import algoliarecommend from "@algolia/recommend";
import { runQuery } from "queries";
import { useEffect, useState, useRef, useCallback } from "react";
import useSWR from "swr";
import { useRouter } from "next/router";
import {
  updateUserSession,
  useGQLSWR,
  fetcher,
  hasuraHeadersFromSession,
} from "utils";
import { gql } from "graphql-request";
import store from "store2";
import Cookies from "js-cookie";

import { getSession, useSession as useSessionNextAuth } from "next-auth/react";

import { equipmentFragment } from "queries/fragments";

const useSession = () => {
  const { data, mutate } = useSWR("session", getSession);
  return {
    data,
    status:
      data === undefined
        ? "loading"
        : data
        ? "authenticated"
        : "unauthenticated",
    mutate,
  };
  /*
  const [session, setSession] = useState();
  const [status, setStatus] = useState("loading");
  useEffect(() => {
    const fetchSession = async () => {
      const response = useSWR("session", getSession());
      setSession(response);
      setStatus(response ? "authenticated" : "unauthenticated");
    };
    if (status == "loading") {
      fetchSession();
    }
  }, []);
  return {
    data: session,
    status,
  };
  */
};

const useUserSession = (session) => {
  const token =
    Cookies.get("next-auth.session-token") ||
    Cookies.get("__Secure-next-auth.session-token") ||
    session?.token;

  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
  const fetcher = (url, headers) =>
    fetch(url, {
      headers: {
        ...headers,
        "X-Session-Id": store.get("session_id"),
        "Cache-Control": "no-cache",
      },
      credentials: "include",
    }).then((r) => r.json());
  const url = `${process.env.NEXT_PUBLIC_REST_API_URL}session/`;
  const { data, mutate, error } = useSWR(url, fetcher, {
    fallbackData: {},
  });
  const loading = !data && !error;
  store.set(
    "session_id",
    (data || {}).session_id || store.get("session_id") || null,
  );

  return {
    userSession: data || {},
    loading,
    mutate,
  };
};

const useUser = () => {
  const { data: session, status } = useSession();
  const { userSession, loading, mutate } = useUserSession(session);
  const user = { ...(session?.user || {}), ...(userSession?.user || {}) };
  return {
    user: Object.keys(user) ? user : undefined,
    loading: loading || status == "loading",
    mutate,
  };
};

const useRecipeLists = () => {
  const { data: session, status } = useSession();
  const loading = status == "loading";
  const query = gql`
            query wishlist {
                wishlists_by_pk(user_id: "${session?.id}") {
                    lists
                }
    }`;
  const { data, mutate } = useGQLSWR(query);
  const recipeLists =
    (data || { wishlists_by_pk: null }).wishlists_by_pk?.lists || [];
  const setRecipeLists = async (newLists) => {
    mutate({ ...data, lists: newLists });
    const query = gql`
        mutation upsert_wishlist($user_id: uuid, $lists: jsonb) {
            insert_wishlists(objects: {user_id: $user_id, lists: $lists}, on_conflict: {constraint: wishlists_pkey, update_columns: lists, where: {user_id: {_eq: "${session.id}"}}}) {
                returning {
                    lists
                }
            }
        }`;
    const response = await fetcher(
      query,
      { user_id: session.id, lists: newLists },
      hasuraHeadersFromSession(session),
    );
    mutate({ ...data, lists: response.insert_wishlists.returning.lists });
  };
  const removeRecipeList = async (list) => {
    await setRecipeLists(recipeLists.filter((l) => l.name != list.name));
  };
  /*
  const { userSession, loading, mutate } = useUserSession();
  console.info(hasuraHeadersFromSession(session));

  const recipeLists = userSession.recipeLists || [];
  */
  return {
    recipeLists,
    loading,
    setRecipeLists,
    removeRecipeList,
  };
};

const useCart = () => {
  const { userSession, loading, mutate } = useUserSession();
  const cart = userSession.cart || { items: [], coupons: {} };
  const setCart = async (newCart) => {
    const newSession = {
      ...userSession,
      cart: { ...cart, ...newCart, timestamp: Date.now() },
    };
    const response = await updateUserSession(newSession);
    mutate(newSession);
    return response.cart;
  };
  const addToCart = async (product, currency, quantity = 1, shipping = 0) => {
    const isNew = !cart.items.find((item) => item.product.id == product.id);
    const items = isNew
      ? [...cart.items].concat([{ product, quantity }])
      : [...cart.items].map((item) => {
          return item.product.id == product.id
            ? { ...item, quantity: item.quantity + quantity }
            : item;
        });
    await setCart({ items, currency, shipping });
  };
  const setQuantity = async (product, quantity) => {
    const items =
      quantity > 0
        ? [...cart.items].map((item) => {
            return item.product.id == product.id ? { ...item, quantity } : item;
          })
        : [...cart.items].filter((item) => item.product.id != product.id);
    await setCart({ items });
  };
  const addCoupon = async (code) => {
    const coupons = cart.coupons || {};
    if (coupons[code] || !!!code) {
      return;
    }
    const newCart = await setCart({
      ...cart,
      coupons: { ...coupons, [code]: true },
    });
    return newCart;
  };
  const removeCoupon = async (code) => {
    const coupons = Object.fromEntries(
      Object.entries(cart.coupons || {}).filter((c) => {
        return c[0] != code;
      }),
    );
    await setCart({ ...cart, coupons: coupons });
  };
  return {
    cart,
    loading,
    setCart,
    addToCart,
    addCoupon,
    setQuantity,
    removeCoupon,
  };
};

const useDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return [debouncedValue, setDebouncedValue];
};

const usePaymentIntent = (appearance = { theme: "stripe" }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(
      `${process.env.NEXT_PUBLIC_REST_API_URL}stripe/create_payment_intent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Session-Id": store.get("session_id"),
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setPaymentIntentId(data.paymentIntentId);
        setClientSecret(data.clientSecret);
      });
  }, []);

  const options = {
    clientSecret,
    paymentIntentId,
    appearance,
  };
  return { options, stripeLoading: !!!options.clientSecret };
};

const useQuery = (query, params = {}, _headers) => {
  const [data, setData] = useState(undefined);
  const [loading, setIsLoading] = useState(false);
  const { data: session, status } = useSession();
  const headers = !!_headers ? _headers : hasuraHeadersFromSession(session);

  useEffect(() => {
    async function fetchData() {
      const response = await runQuery(query, {
        ...(params || {}),
        headers: headers,
      });
      setData(response.props || {});
      setIsLoading(false);
    }
    if (query && !loading && status != "loading") {
      setIsLoading(true);
      fetchData();
    }
  }, [query, status]);

  return {
    data: data || {},
    loading,
  };
};

const useInApp = () => {
  const [inApp, setInApp] = useState(store.session.get("inApp", false));
  const { query, isReady } = useRouter();
  useEffect(() => {
    if (isReady) {
      setInApp(inApp || query.app == "true");
    }
  }, [query, isReady]);
  return inApp;
};

const useWinReady = () => {
  const [winReady, setWinReady] = useState(false);
  useEffect(() => {
    setWinReady(true);
  }, []);
  return winReady;
};

const useMobilePlatform = () => {
  const [platform, setPlatform] = useState();
  const winReady = useWinReady();
  useEffect(() => {
    if (winReady) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        setPlatform("android");
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setPlatform("ios");
      }
    }
  }, [winReady]);
  return platform;
};

const useIsBrowser = () => {
  const winReady = useWinReady();
  return winReady;
};

const usePagination = ({
  defaultValue = [],
  session,
  tableName,
  defaultOrder = "",
  fragment,
  preFragment = "",
  searchableKeys,
  extraFilter = "",
}) => {
  const [data, setData] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [refreshValue, setRefreshValue] = useState(0);
  const fetchIdRef = useRef(0);

  const searchKeyFn = (key, globalFilter) => {
    const path = key.split(".");
    return `{${path
      .map((key) => `${key}:{`)
      .join("")} _ilike: "%${globalFilter}%"}${path.map(() => "}").join("")}`;
  };

  const fetchData = useCallback(
    ({ pageSize = 100, pageIndex, sortBy, globalFilter }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      const orderBy =
        sortBy.length > 0
          ? `${sortBy[0].id}: ${sortBy[0].desc ? "desc" : "asc"}`
          : defaultOrder;
      const where = (
        (globalFilter
          ? `_or: [${searchableKeys
              .map((key) => searchKeyFn(key, globalFilter))
              .join(", ")}]`
          : "") + (extraFilter ? `,${extraFilter}` : "")
      ).replace(/^,/, "");

      const query = gql`
      ${preFragment}
      {
          ${tableName}(where: {${where}}, limit: ${pageSize}, offset: ${
            pageIndex * pageSize
          }, order_by: { ${orderBy} }) {
              ${fragment}
          }
          ${tableName}_aggregate(where: {${where}}) {
              aggregate {
                  count
              }
          }
      }
    `;
      fetcher(query, null, hasuraHeadersFromSession(session)).then((data) => {
        if (fetchId === fetchIdRef.current) {
          const quantity = data[`${tableName}_aggregate`].aggregate.count;
          setData(data[tableName]);
          setQuantity(quantity);
          setPageCount(Math.ceil(quantity / pageSize));
          setLoading(false);
        }
      });
    },
    [refreshValue],
  );

  return {
    data,
    setData,
    loading,
    pageCount,
    quantity,
    fetchData,
    refresh: () => {
      setRefreshValue(refreshValue + 1);
    },
  };
};

export {
  useCart,
  useDebounce,
  usePaymentIntent,
  useQuery,
  useRecipeLists,
  useUserSession,
  useSession,
  useUser,
  useInApp,
  useIsBrowser,
  useWinReady,
  useMobilePlatform,
  usePagination,
};
