import * as cx from "classnames/bind";

import s from "styles/InfoPop.module.scss";

const InfoPop = ({
  className,
  disabled,
  title,
  width,
  position,
  children,
  ...other
}) => {
  return (
    <div
      className={cx("InfoPop", s.InfoPop, className, { Disabled: disabled })}
      htmlFor={other.id}
    >
      <div className={cx("InfoPopHover", s.InfoPopHover)}>
        <i className="InfoPopIcon" />
        <div
          className={cx("InfoPopContent", s.InfoPopContent)}
          position={position}
          style={{ width: width }}
        >
          {title && <h4>{title}</h4>}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default InfoPop;
