import React from "react";
import Select from "react-select";

import * as cx from "classnames/bind";
import s from "styles/Input.module.scss";
import { InfoPop } from "components";

const Multi = ({
  options,
  def,
  label,
  name,
  type,
  infoTitle,
  infoContent,
  infoPos,
  className,
  placeholder,
  errors = {},
  register,
  required,
}) => (
  <React.Fragment>
    <div className={cx("InputContainer", s.InputContainer, className)}>
      {label && (
        <label htmlFor={name} className={cx("InputHeadline", s.InputHeadline)}>
          {label}
        </label>
      )}
      {infoTitle && infoContent && (
        <InfoPop title={infoTitle} position={infoPos}>
          {infoContent}
        </InfoPop>
      )}
      <Select
        defaultValue={def}
        isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <div className={cx("errorMsg", s.errorMsg)}>
        {errors && errors?.message && errors.message}
        {errors?.type === "required" && "This field is required"}
        {errors?.type === "email" && "Must be valid email"}
        {errors?.type === "unique" && "Must be unique"}
        {errors?.type === "minLength" && "Min length error"}
      </div>
    </div>
  </React.Fragment>
);

export default Multi;
