import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import { Icon } from "components";
import Link from "next/link";
import { useRouter } from "next/router";

const HorecaContent = ({ children, title, description, ...other }) => {
  const keys = ["organization", "hotel", "bar"];
  const user = other.user;
  const iconMap = {
    organization: "sitemap",
    hotel: "hotel",
    bar: "wine-bottle",
  };
  const breadcrumbs = keys
    .map((k) => [k, other[k]])
    .filter(([_, o]) => o)
    .map(([k, o]) => [k, Array.isArray(o) ? o[0] : o]);

  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  const bar = user?.availableBars.find((b) => b.id == other?.bar?.id);
  const availableBars = bar
    ? user.availableBars.filter((b) => b.id != bar.id)
    : [];

  return (
    <div className={cx("AdminContent", s.AdminContent)}>
      {(user?.barTender || user?.barManager) && availableBars.length > 0 && (
        <div
          className={cx("contentInner", s.contentInner)}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          {/*
            <div className={cx(s.horecaBreadcrumbs)}>
              <b>You are managing:</b>
              <div className="flex flex-wrap">
                {breadcrumbs.map(([k, o]) => {
                  if (!o?.name) return null; // Prevents rendering empty items

                  const href =
                    k === "organization"
                      ? {
                          pathname: "/horeca/[organization]",
                          query: { organization: o?.slug },
                        }
                      : k === "hotel"
                      ? {
                          pathname: "/horeca/[organization]/hotels/[hotel]",
                          query: {
                            organization: other?.organization?.slug,
                            hotel: o?.slug,
                          },
                        }
                      : k === "bar"
                      ? other.hotel
                        ? {
                            pathname:
                              "/horeca/[organization]/hotels/[hotel]/bars/[bar]",
                            query: {
                              organization: other?.organization?.slug,
                              hotel: other?.hotel?.slug,
                              bar: o?.slug,
                            },
                          }
                        : {
                            pathname: "/horeca/[organization]/bars/[bar]",
                            query: {
                              organization:
                                other?.organization?.slug ||
                                o?.organization?.slug,
                              bar: o?.slug,
                            },
                          }
                      : {};

                  return (
                    <span key={k} className="mr-4">
                      <Link
                        href={href}
                        passHref
                        a
                        className="flex items-center"
                      >
                        <Icon size="far" svg={iconMap[k]} className="mr-2" />
                        {o.name}
                      </Link>
                    </span>
                  );
                })}
                <Link href="/horeca">Edit</Link>
              </div>
            </div>
            */}
          <div className={cx(s.horecaBarSelector)}>
            <div className={cx(s.horecaBarSelectorSelected)}>
              <b>You are managing:</b>
              <div className="flex flex-wrap">
                {breadcrumbs.map(([k, o], index) => {
                  if (!o?.name) return null; // Prevents rendering empty items

                  const href =
                    k === "organization"
                      ? {
                          pathname: "/horeca/[organization]",
                          query: { organization: o?.slug },
                        }
                      : k === "hotel"
                      ? {
                          pathname: "/horeca/[organization]/hotels/[hotel]",
                          query: {
                            organization: other?.organization?.slug,
                            hotel: o?.slug,
                          },
                        }
                      : k === "bar"
                      ? other.hotel
                        ? {
                            pathname:
                              "/horeca/[organization]/hotels/[hotel]/bars/[bar]",
                            query: {
                              organization: other?.organization?.slug,
                              hotel: other?.hotel?.slug,
                              bar: o?.slug,
                            },
                          }
                        : {
                            pathname: "/horeca/[organization]/bars/[bar]",
                            query: {
                              organization:
                                other?.organization?.slug ||
                                o?.organization?.slug,
                              bar: o?.slug,
                            },
                          }
                      : {};
                  if (index === breadcrumbs.length - 1) {
                    return (
                      <span key={k} className="mr-4">
                        <Link
                          href={href}
                          passHref
                          a
                          className="flex items-center"
                        >
                          <Icon size="far" svg={iconMap[k]} className="mr-2" />
                          {o.name}
                        </Link>
                      </span>
                    );
                  }
                })}
              </div>
            </div>
            <div className={cx(s.horecaBarSelectorAvailable)}>
              <b>Manage another bar:</b>
              <div className="flex flex-wrap">
                {availableBars?.map((bar) => {
                  return (
                    <span key={bar.id} className="mr-4">
                      <Link
                        href={`/horeca/${bar.url}`}
                        className="flex items-center"
                      >
                        <Icon size="far" svg="bottle" className="mr-2" />
                        {bar.name}
                      </Link>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={cx("contentInner", s.contentInner)}>
        <div className={cx("contentHeader", s.contentHeader)}>
          {title && <h1>{title}</h1>}
          {description && <p className="m0_reg mt8">{description}</p>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default HorecaContent;
