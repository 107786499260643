import * as cx from "classnames/bind";
import { Button, Icon } from "components";
import { useRecipeLists } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import s from "styles/Modal.module.scss";
import { updateUserSession } from "utils";
import { useTranslation } from "next-i18next";

const CreateListModal = ({ show, onClose, className }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [isBrowser, setIsBrowser] = useState(false);
  const [listName, setListName] = useState("");

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const { recipeLists, setRecipeLists } = useRecipeLists();

  useEffect(() => {
    setDisabled(!listName || recipeLists.find((list) => list.name == listName));
  }, [recipeLists, listName]);

  const createList = async () => {
    const newLists = recipeLists.concat([{ name: listName, items: [] }]);
    await setRecipeLists(newLists);
    setListName("");
    onClose();
  };

  const modalContent = show ? (
    <div className={cx(s.Modal, s.CreateListModal)}>
      <div className={cx(s.ModalOverlay)} onClick={handleCloseClick}></div>
      <div className={cx(s.ModalContent, className)}>
        <div className={cx("ModalHeader", s.ModalHeader)}>
          <div className={cx(s.ModalTitle)}>{t("Create new menu")}</div>
          <a href="#" onClick={handleCloseClick}>
            <img alt="" src="/img/icons/close.svg" />
          </a>
        </div>
        <div className={cx(s.ModalBody)}>
          <div className="inputContainer">
            <label>{t("Menu name")}:</label>
            <input
              type="text"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              placeholder={t("Give your menu a unique name")}
            />
          </div>
          <div className="ButtonGroup flex">
            <Button
              onClick={handleCloseClick}
              className="Primary Hollow Cancel"
            >
              {t("Cancel")}
            </Button>
            <input
              disabled={disabled}
              type="submit"
              className="Button Primary Rad flex-grow"
              value={t("Create menu")}
              onClick={() => {
                createList();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root"),
    );
  } else {
    return null;
  }
};

export default CreateListModal;
