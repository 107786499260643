import * as cx from "classnames/bind";
import s from "styles/Container.module.scss";

const Container = ({ children, className, ...other }) => (
  <div className={cx("Container", s.Container, className)} {...other}>
    {children}
  </div>
);

export default Container;
