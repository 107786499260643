import React from "react";
import * as cx from "classnames/bind";
import { Icon } from "components";
import s from "styles/Button.module.scss";
//import imageCompression from "browser-image-compression";

const FileButton = ({
  label,
  className,
  size,
  svg,
  children,
  name = "file",
  onChange,
  onClick,
  loading = false,
  accept,
  ...other
}) => {
  const inputRef = React.createRef();
  const formRef = React.createRef();
  const _onClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
    inputRef.current.click();
  };
  const _onChange = async (event) => {
    if (loading) {
      return;
    }
    const imageFile = event.target.files[0];
    try {
      /*
    const compressedFile = await imageCompression(imageFile, options);
      const formData = new FormData(formRef.current);
      formData.set(name, compressedFile);
      */
      onChange(new FormData(formRef.current));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <form method="POST" ref={formRef}>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        name={name}
        onChange={_onChange}
        ref={inputRef}
      />

      <button
        className={cx("Button", s.Button, className)}
        {...other}
        type="button"
        disabled={loading}
        onClick={_onClick}
      >
        {loading ? "Loading" : children}
        {loading ? (
          <Icon svg="spinner" spin />
        ) : (
          svg && <Icon size={size} svg={svg} />
        )}
      </button>
    </form>
  );
};

export default FileButton;
