import * as cx from "classnames/bind";
import s from "styles/Bar.module.scss";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { NavLink, Icon, Container, ButtonLink } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetcher } from "utils";
import { useSession } from "hooks";
import store from "store2";

const Bar = ({ ...other }) => {
  const [isBarOpen, setIsBarOpen] = useState(false);

  const [isBarClosed, setIsBarClosed] = useState(
    store.session("barClosed") || false,
  );

  return (
    <div className={cx("AnnouncementBar", s.Bar)}>
      {store.session.get("barClosed", isBarClosed) || isBarOpen ? (
        <></>
      ) : (
        <div className={cx(s.mobilePopup, isBarOpen ? s.close : "")}>
          <Container>
            <div className={cx(s.mobilePopupContent, "text-center")}>
              <div className={cx("desc-only", s.mobilePopupImg)}></div>
              <div className={cx(s.mobilePopupTitle, "Heading__small")}>
                <b>Q-KIT</b>
                <p className="tab-up">
                  Looking for the ultimate gift for the cocktail-lover in your
                  life this Christmas? <br className="desc-only" />
                  Bring the love with our luxury{" "}
                  <Link href="/q-kit">17-piece home bartending kit</Link>
                </p>
                <p className="tab-down">
                  Bring the Christmas love with our luxury{" "}
                  <Link href="/q-kit">17-piece home bartending kit</Link>
                </p>
              </div>
              <ButtonLink
                className={cx("Small White Hollow desc-only", s.shopBtn)}
                href="/q-kit"
              >
                Shop now
              </ButtonLink>
              <div className={cx("hidden", s.closeBtn)}>
                <Icon
                  svg="times"
                  size="far"
                  onClick={() => {
                    setIsBarClosed(true);
                    store.session("barClosed", true);
                    setIsBarOpen(!isBarOpen);
                  }}
                />
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Bar;
