import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

import { useIsBrowser } from "hooks";
import Image from "next/image";
import Link from "next/link";
import * as cx from "classnames/bind";
import s from "styles/LegalPopup.module.scss";

const LegalPopup = ({ show, onClose, className }) => {
  const isBrowser = useIsBrowser();
  const handleDecline = () => {
    window.location.href = "https://responsibledrinking.eu/";
  };
  return isBrowser ? (
    <CookieConsent
      disableStyles
      containerClasses={cx("text-center", s.legalPopup)}
      buttonWrapperClasses={cx("ButtonGroup", s.buttonWrapper)}
      buttonClasses={cx("Button", s.acceptButton)}
      declineButtonClasses={cx("Button ButtonLink Cancel", s.declineButton)}
      contentClasses={cx(s.legalPopupBody)}
      overlayClasses={cx(s.legalPopupOverlay)}
      overlay
      enableDeclineButton
      location="bottom"
      declineButtonText="NO"
      buttonText="YES"
      cookieName="CookieConsent"
      onDecline={handleDecline}
    >
      <Image
        alt="Cocktail Club symbol"
        src="/img/cc_symbol-black.png"
        width={80}
        height={80}
      />
      <div className={cx("Heading Light", s.LegalHeading)}>
        Welcome to
        <br />
        Cocktail Club
      </div>
      <small>
        <b>
          You may not use this service if you are under the age of 18 or the age
          of legal majority in your jurisdiction.
        </b>
      </small>
      <br />
      <small>
        By entering this website you acknowledge and represent that you are 18
        years or older and have reviewed and agree to our{" "}
        <Link href="/service/terms" target="_blank">
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link href="/service/privacy" target="_blank">
          Privacy Policy
        </Link>
      </small>
      <div className="Heading__sub mt18">Are you above legal drinking age?</div>
    </CookieConsent>
  ) : null;
};

export default LegalPopup;
