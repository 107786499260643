import * as cx from "classnames/bind";
import { Button, ButtonLink, Icon, Input, Loader } from "components";
import * as emailValidator from "email-validator";
import Link from "next/link";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useInApp } from "hooks";
import { useSession } from "next-auth/react";
import { signOut, getSession } from "next-auth/react";
import { Controller, useForm } from "react-hook-form";
import { checkIfEmailExists, hasuraHeadersFromSession, fetcher } from "utils";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import s from "styles/Modal.module.scss";
import store from "store2";
import Cookies from "js-cookie";
import { gql } from "graphql-request";

export default function LoginModal({
  csrfToken,
  state,
  setLoginModalState,
  message,
  onClose,
  className,
  cocktail,
}) {
  const { t } = useTranslation();
  const [isBrowser, setIsBrowser] = useState(false);
  const [isLostPassword, setIsLostPassword] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const [tickets, setTickets] = useState([]);

  const { data: session, status, update } = useSession();

  //const tickets = session?.user?.extraData?.tickets || [];
  const country = (Cookies.get("__country") || "no").toLowerCase();

  const userHasEnteredEvent = session?.user && tickets.includes(country);

  const router = useRouter();
  const currentUrl = router.asPath;

  const isInApp = useInApp();

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    setTickets(session?.user?.extraData?.tickets || []);
  }, [session]);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const logOut = () => {
    signOut("credentials", { callbackUrl: currentUrl });
  };

  const enterCompetition = async () => {
    if (!session?.user || userHasEnteredEvent) {
      return;
    }
    const headers = hasuraHeadersFromSession(session);
    const extraData = session.user?.extraData || {};
    const mutation = gql`
      mutation UpdateUser($user_id: uuid!, $extra_data: jsonb!) {
        update_users_by_pk(
          pk_columns: { id: $user_id }
          _set: { extra_data: $extra_data }
        ) {
          id
          extra_data
        }
      }
    `;
    const response = await fetcher(
      mutation,
      {
        user_id: session.user.id,
        extra_data: {
          ...extraData,
          tickets: [...new Set((extraData?.tickets || []).concat(country))],
        },
      },
      headers,
    );
    setTickets(response.update_users_by_pk.extra_data.tickets);
  };

  const {
    register,
    control,
    watch,
    setError,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
  } = useForm();

  const onLoginSubmit = async (data) => {
    const [response, emailResponse] = await Promise.all([
      signIn("credentials", { ...data, redirect: false }),
      checkIfEmailExists(data.email),
    ]);
    if (response.ok) {
      store("hasLoggedIn", true);
      setLoginModalState({
        ...(state.event ? state : {}),
        show: !!state.event,
        index: state.event ? 2 : 0,
      });
      const timer = setTimeout(function () {
        router.reload();
      }, 1000);
      const listener = document.addEventListener("visibilitychange", () => {
        clearTimeout(timer);
        document.removeEventListener("visibilitychange", listener);
      });
      const event = new Event("visibilitychange");
      document.dispatchEvent(event);
    } else {
      setWrongCredentials(
        emailResponse.detail !== "exists"
          ? t(
              "The email you provided is not registered. Please register an account or try again.",
            )
          : t(
              "The password you provided does not seem to be correct. Please try again.",
            ),
      );
    }
  };

  const onRegisterSubmit = async (data) => {
    const url = process.env.NEXT_PUBLIC_REST_API_URL + "register";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Locale: router.locale,
      },
      body: JSON.stringify({
        ...data,
        display_name: data.display_name,
        role: "user",
        ambassador: state.ambassador,
        extra_data: state.event ? { tickets: [country] } : {},
      }),
    });
    if (!response.ok) {
      setError("email", {
        type: "unavailable",
        message: t("Email is already in use"),
      });
    } else {
      update();
      if (state.afterRegister instanceof Function) {
        state.afterRegister();
        await signIn("credentials", {
          email: data.email,
          password: data.password,
          redirect: false,
        });
      } else {
        await signIn("credentials", {
          email: data.email,
          password: data.password,
          redirect: false,
        });
        setLoginModalState({
          ...(state.event ? state : {}),
          show: !!state.event,
          index: state.event ? 2 : 0,
        });
        if (!state.event) {
          onClose();
        }
      }
    }
  };

  const modalContent = state.show ? (
    <div className={cx("LoginModal", s.Modal, s.LoginModal)}>
      <div className={cx(s.ModalOverlay)} onClick={handleCloseClick}></div>
      <div
        className={cx(
          s.ModalContent,
          s.LoginModalContainer,
          s.mobileBottom,
          "mobileBottom",
        )}
      >
        <div className={cx(s.LoginModalBody)}>
          <a href="#" title={t("Close")} onClick={handleCloseClick}>
            <img alt={t("Close")} src="/img/icons/close.svg" />
          </a>
          <Tabs selectedIndex={state.index}>
            <div className={cx("LoginModalHeader", s.LoginModalHeader)}>
              <TabList style={{ visibility: session?.user && "hidden" }}>
                <Tab
                  onClick={() =>
                    setLoginModalState({ ...state, show: true, index: 0 })
                  }
                >
                  <img alt={t("Sign in")} src="/img/icons/sign-in-solid.svg" />
                  {t("Log In")}
                </Tab>
                <Tab
                  onClick={() =>
                    setLoginModalState({ ...state, show: true, index: 1 })
                  }
                >
                  <img
                    alt={t("Sign up")}
                    src="/img/icons/user-plus-solid.svg"
                  />
                  {t("Register")}
                </Tab>
              </TabList>
            </div>
            <div className={cx("LoginModalContent", s.LoginModalContent)}>
              <TabPanel>
                {!isLostPassword ? (
                  <>
                    {state.message ? (
                      <h2 className="Heading__sub Bolder">{state.message}</h2>
                    ) : (
                      <h2 className="Heading__sub Bolder">
                        {t("Welcome back! Get ready for some cocktails!")}
                      </h2>
                    )}
                    <form
                      id="loginModalForm"
                      method="post"
                      action="/api/auth/callback/credentials?callbackUrl=/my-account"
                      onSubmit={handleSubmit(onLoginSubmit)}
                    >
                      <div className="inputContainer noLabel">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          placeholder={t("Email")}
                          {...register("email", { required: true })}
                        />
                        <label htmlFor="email">Email</label>
                      </div>
                      <div className="inputContainer noLabel">
                        <input
                          name="password"
                          id="password"
                          type="password"
                          placeholder={t("Password")}
                          {...register("password", { required: true })}
                        />
                        <label htmlFor="password">{t("Password")}</label>
                      </div>
                      {wrongCredentials && (
                        <p className="warning">{wrongCredentials}</p>
                      )}
                      <div className="inputContainer">
                        <input
                          type="submit"
                          className="Button Primary Full"
                          value={t("Sign in")}
                        />
                      </div>
                    </form>
                    <div className="text-center">
                      <ButtonLink href="/profile/reset">
                        {t("Lost your password?")}
                      </ButtonLink>
                      {/* For the future probably.
                      <Button
                        className="ButtonLink"
                        onClick={() => setIsLostPassword(true)}
                      >
                        Lost your password?
                      </Button>*/}
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className="Heading__sub Bolder">
                      {t("Lost your password?")}
                    </h2>
                    <form>
                      <div className="inputContainer mb12">
                        <label htmlFor="confirm_email">
                          {t(
                            "Please enter your email address. You will receive mail with link to set new password",
                          )}
                        </label>
                        <input
                          name="confirm_email"
                          id="confirm_email"
                          type="email"
                          placeholder={t("Email")}
                        />
                      </div>
                      <div className="inputContainer">
                        <input
                          type="submit"
                          className="Button Primary Full Cyan"
                          value={t("Reset password")}
                        />
                      </div>
                    </form>
                    <div className="text-center">
                      <Button
                        className="ButtonLink"
                        onClick={() => setIsLostPassword(false)}
                      >
                        {t("Back to login")}
                      </Button>
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {state.message ? (
                  <h2 className="Heading__sub Bolder">{state.message}</h2>
                ) : (
                  <h2 className="Heading__sub Bolder">
                    {t("Sign up to continue with full access!")}
                  </h2>
                )}

                {state.event && (
                  <>
                    <p>
                      This month we are giving away a free trip to Barcelona for
                      you and your friend.
                      <br />
                      <b>Sign up and download our app to win.</b>
                    </p>
                  </>
                )}
                <ul className="mb12 hidden">
                  <li>- {t("Full access to all recipes")}</li>
                  <li>- {t("Step-by-step video guides")}</li>
                  <li>
                    - {t("Chance to win a")}&nbsp;
                    <b>
                      <Link href="/q-kit" target="_blank">
                        {t("Cocktail Kit")}
                      </Link>
                    </b>
                  </li>
                </ul>
                <form
                  id="registerModalForm"
                  method="post"
                  action="/api/auth/callback/credentials?callbackUrl=/"
                  onSubmit={handleSubmit(onRegisterSubmit)}
                >
                  {isSubmitting && <Loader />}
                  <Controller
                    name="display_name"
                    control={control}
                    rules={{
                      required: { value: true, message: t("Name is required") },
                    }}
                    render={({ field }) => (
                      <Input
                        className="noLabel"
                        placeholder={t("John Doe")}
                        field={field}
                        errors={errors}
                        label={t("Name")}
                        value={field.value}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      validate: {
                        email: (v) =>
                          emailValidator.validate(v) || t("Not a valid email"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        className="noLabel"
                        placeholder={t("mail@example.com")}
                        field={field}
                        errors={errors}
                        label={t("Email")}
                        type="email"
                        value={field.value}
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("Password is required"),
                      },
                      minLength: {
                        value: 8,
                        message: t("Password must be minimum 8 characters"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        className="noLabel"
                        placeholder={t("**********")}
                        field={field}
                        type="password"
                        errors={errors}
                        label={t("Password")}
                        value={field.value}
                      />
                    )}
                  />
                  <div className="checkContainer">
                    <input
                      id="newsletter"
                      type="checkbox"
                      defaultChecked
                      {...register("newsletter")}
                    />
                    <label htmlFor="newsletter">
                      {t("Subscribe to our newsletter")}
                    </label>
                  </div>

                  <div className={cx("inputContainer", s.registerModalbtn)}>
                    <input
                      type="submit"
                      className={cx(
                        "Button Primary Full",
                        state.event && "EventButton",
                      )}
                      value={t("Create account")}
                    />
                  </div>
                  <p className="disclaimer-text mb0">
                    {state.event && (
                      <>
                        <br />
                        <p className="Heading__small">
                          <b>Competition rules</b>
                        </p>
                        <b>Privacy Policy</b>
                        <br />

                        <ul style={{ listStyle: "disc", paddingLeft: "12px" }}>
                          <li>
                            By participating in the Competition, participants
                            consent to the collection and use of their personal
                            information for the purposes of administering the
                            Competition.
                          </li>
                          <li>
                            Personal data, such as email addresses and contact
                            details, will not be shared with third parties
                            without consent, except as required by law.
                          </li>
                          <li>
                            Participants may request to view, amend, or delete
                            their data at any time by contacting Cocktail Club
                            at privacy@cocktailclub.com.
                          </li>
                        </ul>
                        <br />

                        <b>Limitation of Liability</b>
                        <br />
                        <ul style={{ listStyle: "disc", paddingLeft: "12px" }}>
                          <li>
                            Cocktail Club and its affiliates will not be
                            responsible for any loss, damage, or injury arising
                            from participation in the Competition or the
                            acceptance of the prize.
                          </li>
                          <li>
                            Cocktail Club reserves the right to modify, suspend,
                            or cancel the Competition if technical failures,
                            fraud, or any other causes beyond its control impact
                            the fairness or integrity of the Competition.
                          </li>
                        </ul>
                        <br />

                        <b>Intellectual Property</b>
                        <br />
                        <ul style={{ listStyle: "disc", paddingLeft: "12px" }}>
                          <li>
                            All promotional materials, including but not limited
                            to videos, flyers, and landing pages, remain the
                            property of Cocktail Club. Participants do not gain
                            any rights to these materials by participating in
                            the Competition.
                          </li>
                          <li>
                            Any disputes arising from or related to the
                            Competition shall be subject to the jurisdiction of
                            the courts of that country.
                          </li>
                        </ul>
                        <br />

                        <b>Disqualification</b>
                        <br />
                        <ul style={{ listStyle: "disc", paddingLeft: "12px" }}>
                          <li>
                            Cocktail Club reserves the right to disqualify any
                            participant who tampers with the entry process,
                            engages in fraudulent activity, or breaches these
                            Terms and Conditions.
                          </li>
                          <li>
                            Entries made through robotic, automatic, or
                            fraudulent means will not be accepted and will lead
                            to disqualification.
                          </li>
                        </ul>
                        <br />

                        <b>Governing Law</b>
                        <br />
                        <ul style={{ listStyle: "disc", paddingLeft: "12px" }}>
                          <li>
                            These Terms and Conditions are governed by the laws
                            of the country in which the participating store is
                            located.
                          </li>
                          <li>
                            Any disputes arising from or related to the
                            Competition shall be subject to the jurisdiction of
                            the courts of that country.
                          </li>
                        </ul>
                        <br />

                        <b>Amendments</b>
                        <br />
                        <ul style={{ listStyle: "disc", paddingLeft: "12px" }}>
                          <li>
                            Cocktail Club reserves the right to amend these
                            Terms and Conditions at any time. Any amendments
                            will be communicated to participants through the
                            official competition page or in-store announcements.
                          </li>
                        </ul>
                        <br />
                      </>
                    )}
                    {t("By clicking Create account you agree to our")}&nbsp;
                    <Link href="/service/terms">
                      {t("Terms of Service")}
                    </Link>.{" "}
                    {t(
                      "Your personal information is used to increase the usability of this website, give you access to the administration of your user account and other purposes as described in our",
                    )}
                    &nbsp;
                    <Link href="/service/privacy">Privacy Policy</Link>.
                  </p>
                </form>
              </TabPanel>
              {state.event && (
                <>
                  <TabPanel className="text-center">
                    {status != "authenticated" ? (
                      <Loader />
                    ) : !userHasEnteredEvent ? (
                      <>
                        <h2 className="Heading__sub Bolder">{state.message}</h2>
                        <p>
                          This month we are giving away a free trip to Barcelona
                          for you and your friend.
                        </p>
                        <p>
                          {t("You are signed in with")}{" "}
                          <b>{session?.user?.email}</b>.<br />
                          {t("Click button below to enter the competition.")}
                        </p>
                        <Button
                          className="Primary Cyan"
                          href="/download"
                          onClick={() => enterCompetition()}
                        >
                          {t("Enter competition")}
                        </Button>
                        <br />
                        <br />
                        <a
                          title={t("Sign out")}
                          onClick={() => {
                            store.remove("session_id");
                            signOut();
                          }}
                        >
                          {t("Use another account? Sign out")}
                        </a>
                        <p
                          className="disclaimer-text mb0 text-left"
                          style={{ textAlign: "left" }}
                        >
                          {state.event && (
                            <>
                              <br />
                              <p className="Heading__small">
                                <b>Competition rules</b>
                              </p>
                              <b>Privacy Policy</b>
                              <br />

                              <ul
                                style={{
                                  listStyle: "disc",
                                  paddingLeft: "12px",
                                }}
                              >
                                <li>
                                  By participating in the Competition,
                                  participants consent to the collection and use
                                  of their personal information for the purposes
                                  of administering the Competition.
                                </li>
                                <li>
                                  Personal data, such as email addresses and
                                  contact details, will not be shared with third
                                  parties without consent, except as required by
                                  law.
                                </li>
                                <li>
                                  Participants may request to view, amend, or
                                  delete their data at any time by contacting
                                  Cocktail Club at privacy@cocktailclub.com.
                                </li>
                              </ul>
                              <br />

                              <b>Limitation of Liability</b>
                              <br />
                              <ul
                                style={{
                                  listStyle: "disc",
                                  paddingLeft: "12px",
                                }}
                              >
                                <li>
                                  Cocktail Club and its affiliates will not be
                                  responsible for any loss, damage, or injury
                                  arising from participation in the Competition
                                  or the acceptance of the prize.
                                </li>
                                <li>
                                  Cocktail Club reserves the right to modify,
                                  suspend, or cancel the Competition if
                                  technical failures, fraud, or any other causes
                                  beyond its control impact the fairness or
                                  integrity of the Competition.
                                </li>
                              </ul>
                              <br />

                              <b>Intellectual Property</b>
                              <br />
                              <ul
                                style={{
                                  listStyle: "disc",
                                  paddingLeft: "12px",
                                }}
                              >
                                <li>
                                  All promotional materials, including but not
                                  limited to videos, flyers, and landing pages,
                                  remain the property of Cocktail Club.
                                  Participants do not gain any rights to these
                                  materials by participating in the Competition.
                                </li>
                                <li>
                                  Any disputes arising from or related to the
                                  Competition shall be subject to the
                                  jurisdiction of the courts of that country.
                                </li>
                              </ul>
                              <br />

                              <b>Disqualification</b>
                              <br />
                              <ul
                                style={{
                                  listStyle: "disc",
                                  paddingLeft: "12px",
                                }}
                              >
                                <li>
                                  Cocktail Club reserves the right to disqualify
                                  any participant who tampers with the entry
                                  process, engages in fraudulent activity, or
                                  breaches these Terms and Conditions.
                                </li>
                                <li>
                                  Entries made through robotic, automatic, or
                                  fraudulent means will not be accepted and will
                                  lead to disqualification.
                                </li>
                              </ul>
                              <br />

                              <b>Governing Law</b>
                              <br />
                              <ul
                                style={{
                                  listStyle: "disc",
                                  paddingLeft: "12px",
                                }}
                              >
                                <li>
                                  These Terms and Conditions are governed by the
                                  laws of the country in which the participating
                                  store is located.
                                </li>
                                <li>
                                  Any disputes arising from or related to the
                                  Competition shall be subject to the
                                  jurisdiction of the courts of that country.
                                </li>
                              </ul>
                              <br />

                              <b>Amendments</b>
                              <br />
                              <ul
                                style={{
                                  listStyle: "disc",
                                  paddingLeft: "12px",
                                }}
                              >
                                <li>
                                  Cocktail Club reserves the right to amend
                                  these Terms and Conditions at any time. Any
                                  amendments will be communicated to
                                  participants through the official competition
                                  page or in-store announcements.
                                </li>
                              </ul>
                              <br />

                              <b>Get 10 more draws!</b>
                              <br />
                              <ul
                                style={{
                                  listStyle: "disc",
                                  paddingLeft: "12px",
                                }}
                              >
                                <li>
                                  If you have purchased a kit send us a picture
                                  of you and the recipt and we will award you
                                  with 10 more draws for the competition.
                                </li>
                                <li>
                                  If uncovered that you are not the owner of the
                                  kit. You will be excluded from the competition
                                </li>
                                <br />
                                <li>
                                  Send to{" "}
                                  <a href="mailto:info@cocktailclub.com">
                                    info@cocktailclub.com
                                  </a>
                                </li>
                              </ul>
                              <br />
                            </>
                          )}
                        </p>
                      </>
                    ) : (
                      <>
                        <h2 className="Heading__sub Bolder">
                          You have successfully entered the competition!
                        </h2>
                        <p>Thank you for using Cocktail Club.</p>
                        {!isInApp && (
                          <ButtonLink
                            target="_blank"
                            className="Primary Cyan"
                            href="/download"
                          >
                            Download our app
                          </ButtonLink>
                        )}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel className="text-center">
                    <h2 className="Heading__sub Bolder">
                      You have successfully entered the competition!
                    </h2>
                    <p>Thank you for using Cocktail Club.</p>
                    {!isInApp && (
                      <ButtonLink
                        target="_blank"
                        className="Primary Cyan"
                        href="/download"
                      >
                        Download our app
                      </ButtonLink>
                    )}
                  </TabPanel>
                </>
              )}
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root"),
    );
  } else {
    return null;
  }
}
