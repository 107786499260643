import dynamic from "next/dynamic";
import * as cx from "classnames/bind";
//import { Icon } from "components";
const Icon = dynamic(() => import("components/atoms/Icon"));
import s from "styles/Button.module.scss";

const Button = ({
  label,
  className,
  size,
  svg,
  leftIcon,
  children,
  ...other
}) => (
  <button
    className={cx("Button", s.Button, className, leftIcon ? "leftIcon" : "")}
    {...other}
  >
    {svg && leftIcon && <Icon size={size} svg={svg} />}
    {children}
    {svg && !leftIcon && <Icon size={size} svg={svg} />}
  </button>
);

export default Button;
