import * as cx from "classnames/bind";
import s from "styles/Home.module.scss";
import Link from "next/link";
import { Bar } from "components";

const HomeContent = ({ children, ...other }) => (
  <main
    itemScope
    itemProp="mainContentOfPage"
    className={cx("HomeContent", s.HomeContent)}
  >
    {/*<Bar title="" link="/q-kit" img="/img/qkit1.jpg"></Bar>*/}
    {children}
  </main>
);

export default HomeContent;
