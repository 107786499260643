import Link from "next/link";
import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import { useSession, signOut } from "next-auth/react";
import { NavLink, Icon } from "components";
import { useTranslation } from "next-i18next";

const UserMenu = () => {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const { t } = useTranslation();

  const logOut = () => {
    signOut("credentials", { callbackUrl: "/login" });
  };

  return loading ? null : (
    <div className={cx("UserMenu", s.UserMenu)}>
      <NavLink href="/admin/profile/settings">
        <a>
          <Icon size="far" svg="user" />
          <a>{session?.user.email}</a>
        </a>
      </NavLink>
      <a onClick={logOut}>
        <Icon size="far" svg="power-off" />
        {t("Log out")}
      </a>
    </div>
  );
};

export default UserMenu;
