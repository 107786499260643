import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";

const AdminSection = ({ heading, desc, children, ...other }) => (
  <section className={cx("AdminSection", s.AdminSection)}>
    <div className={cx("sectionHeader", s.sectionHeader)}>
      {heading && <h3>{heading}</h3>}
      {desc && <p>{desc}</p>}
    </div>
    <div className={cx("sectionContent", s.sectionContent)}>{children}</div>
  </section>
);

export default AdminSection;
