import * as cx from "classnames/bind";
import s from "styles/Input.module.scss";

import { InfoPop } from "components";
import InputContainer from "components/atoms/Input/InputContainer";

const Textarea = ({
  name,
  type,
  value,
  placeholder,
  field,
  error,
  ...props
}) => {
  return (
    <InputContainer {...props} {...field}>
      <textarea
        type={type}
        placeholder={placeholder}
        name={name}
        id={name}
        value={value}
        {...field}
        {...props}
      />
    </InputContainer>
  );
};

export default Textarea;
