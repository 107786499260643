import * as jose from "jose";
import NextAuth from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";
//import FacebookProvider from "next-auth/providers/facebook";

const f = async (method, endpoint, data) =>
  fetch(
    `${process.env.NEXT_PUBLIC_REST_API_URL}${endpoint.replace(/^\//, "")}`,
    {
      method,
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": process.env.API_KEY,
      },
      body: JSON.stringify(data),
    },
  );

const get = async (endpoint, data) => f("GET", endpoint, data);
const post = async (endpoint, data) => f("POST", endpoint, data);

export const authOptions = {
  // Configure one or more authentication providers
  secret: process.env.JWT_SECRET,
  providers: [
    /*
    FacebookProvider({
      clientId: process.env.FACEBOOK_CLIENT_ID,
      clientSecret: process.env.FACEBOOK_CLIENT_SECRET,
    }),
    */
    CredentialsProvider({
      credentials: {
        email: { label: "Email", type: "text" },
        password: { label: "Password", type: "password" },
      },
      async authorize(credentials) {
        const url = process.env.NEXT_PUBLIC_REST_API_URL + "login";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credentials),
        });
        const user = await response.json();
        if (response.ok && user) {
          return { ...user, name: user.display_name };
        } else {
          return null;
        }
      },
    }),
  ],
  jwt: {
    secret: process.env.JWT_SECRET,
    encode: async ({ secret, token }) => {
      const user = token.user;
      if (!token.checkedId) {
        const response = await get("/auth/get_id_from_email/" + token.email);
        token.id = await response.text();
        token.checkedId = true;
      }
      const jwtClaims = user
        ? {
            sub: user.id,
            email: user.email,
            iat: Date.now() / 1000,
            exp: Math.floor(Date.now() / 1000) + 24 * 60 * 60,
            user: user,
            "https://hasura.io/jwt/claims": {
              "x-hasura-allowed-roles": [
                "anonymous",
                "user",
                "bartender",
                "admin",
              ],
              "x-hasura-default-role": "anonymous",
              "x-hasura-role": user.role,
              "x-hasura-user-id": user.id,
            },
          }
        : token;
      const encodedToken = await new jose.SignJWT(token, jwtClaims)
        .setProtectedHeader({ alg: "HS256" })
        .sign(new TextEncoder().encode(secret));
      return encodedToken;
    },
    decode: async ({ secret, token }) => {
      const { payload } = await jose.jwtVerify(
        token,
        new TextEncoder().encode(secret),
      );
      return payload;
    },
  },
  callbacks: {
    async session({ session, token }) {
      token["https://hasura.io/jwt/claims"] = {
        "x-hasura-allowed-roles": ["anonymous", "user", "bartender", "admin"],
        "x-hasura-default-role": "anonymous",
        "x-hasura-role": token.user.role,
        "x-hasura-user-id": token.user.id,
      };
      const encodedToken = await new jose.SignJWT(token)
        .setProtectedHeader({ alg: "HS256" })
        .setIssuedAt()
        .sign(new TextEncoder().encode(process.env.JWT_SECRET));
      session.id = token.id;
      session.token = encodedToken;
      session.user = {
        ...token.user,
        id: token.id,
        email: token.email,
        role: token.user.role || "user",
        name: token.user.displayName || token.name,
      };
      return session;
    },
    async jwt({ token, user }) {
      const isUserSignedIn = user ? true : false;
      user && (token.user = user);
      if (isUserSignedIn) {
        token.id = user.id.toString();
      }
      return token;
    },
    async signIn({ user: { email }, account }) {
      const response = await post("/auth/is_allowed_to_login", {
        email,
        account,
      });
      return response.status === 200;
    },
    async redirect({ url, baseUrl }) {
      return url;
    },
  },
  events: {
    async signIn({ user, account }) {
      if (account.provider !== "credentials") {
        const response = await post("/auth/create_oauth_user", {
          user,
          account,
        });
      }
    },
  },
  pages: {
    signIn: "/login",
  },
};

export default NextAuth(authOptions);
