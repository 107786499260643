import * as cx from "classnames/bind";
import * as emailValidator from "email-validator";
import s from "styles/Footer.module.scss";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { ButtonLink, NavLink, Icon, Container } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetcher } from "utils";
import { useSession, useMobilePlatform } from "hooks";
import store from "store2";

import { useTranslation } from "next-i18next";

const Footer = ({ ...other }) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [isBrowser, setIsBrowser] = useState(false);

  const [isDeclined, setIsDeclined] = useState(
    store.session("declined") || false,
  );
  const inAppChecked = store.session.get("inAppChecked", false) || false;
  const submitEmail = async () => {
    await fetch(
      `${process.env.NEXT_PUBLIC_REST_API_URL}subscribe_to_newsletter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
        credentials: "include",
      },
    );
    setIsSuccess(true);
  };
  const [loaded, setLoaded] = useState(false);

  useEffect(() => setIsBrowser(true), []);

  return (
    <footer className={cx("Footer", s.Footer)}>
      <div className={cx(s.footerTop)}>
        <Container className="center">
          <h2 className="Heading Light text-center">
            {t("Sign up to our newsletter")}
          </h2>
          <div className="inputContainer flex">
            <input
              type="email"
              id="newsletter_email"
              name="newsletter_email"
              placeholder={t("Email")}
              value={email}
              disabled={isSuccess}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              onClick={() => submitEmail()}
              disabled={!emailValidator.validate(email)}
              type="submit"
              className="Button Cyan"
              value={t("Subscribe")}
            />
          </div>
          {isSuccess && (
            <p>
              {t("You have successfully been subscribed to our newsletter!")}
            </p>
          )}
        </Container>
      </div>
      <div className={cx(s.footerContent)}>
        <Container>
          <div className={cx("row", s.footerColumnHolder)}>
            <div className={cx("rowItem", s.footerColumn)}>
              <h4>{t("Information")}</h4>
              <ul>
                <li>
                  <Link href="/service/contact">{t("Contact us")}</Link>
                </li>
                <li>
                  <Link href="/service/faq">{t("FAQ")}</Link>
                </li>
                <li>
                  <Link href="/service/shipping">{t("Shipping")}</Link>
                </li>
                <li>
                  <Link href="/service/returns">{t("Returns")}</Link>
                </li>
                <li>
                  <Link href="/service/terms">{t("Terms & conditions")}</Link>
                </li>
                <li>
                  <Link href="/service/privacy">{t("Privacy policy")}</Link>
                </li>
                <li>
                  <Link href="/service/cookies">{t("Cookies")}</Link>
                </li>
              </ul>
            </div>
            <div className={cx("rowItem", s.footerColumn)}>
              <h4>{t("Services")}</h4>
              <ul>
                <li>
                  <Link href="/scanner">{t("Bottle scanner BETA")}</Link>
                </li>
                <li>
                  <Link href="/cocktails">{t("Recipes")}</Link>
                </li>
                <li>
                  <Link href="/products">{t("Products")}</Link>
                </li>
                <li>
                  <Link href="https://www.cocktailclubtools.com/">
                    {t("Bar tools")}
                  </Link>
                </li>
                <li>
                  <Link href="/journal/getting-started">
                    {t("Gettings started")}
                  </Link>
                </li>
                <li>
                  <Link href="/journal">{t("Journal")}</Link>
                </li>
                <li>
                  <Link href="/events">{t("Events")}</Link>
                </li>
                <li>
                  <Link href="/z2h">{t("Zero to hero")}</Link>
                </li>
              </ul>
            </div>
            <div className={cx("rowItem", s.footerColumn)}>
              <h4>Cocktail Club</h4>
              <ul>
                <li>
                  <Link href="/media">{t("Media coverage")}</Link>
                </li>
                <li>
                  <Link href="/journal/cocktail-club/the-story-of-cocktailclub">
                    {t("Our story")}
                  </Link>
                </li>
                <li>
                  <Link href="/ambassadors">{t("Ambassadors")}</Link>
                </li>
                <li>
                  <Link href="/partner">{t("Become partner")}</Link>
                </li>
              </ul>
            </div>
            <div className={cx("rowItem", s.footerColumn)}>
              <h4>{t("Follow us")}</h4>
              <ul>
                <li>
                  <Link
                    href="https://www.instagram.com/cocktailclub_com"
                    target="_blank"
                  >
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.facebook.com/CocktailClubNordic"
                    target="_blank"
                  >
                    Facebook
                  </Link>
                </li>
                <li className="hidden">
                  <Link
                    href="https://www.twitter.com/cocktailclub"
                    target="_blank"
                  >
                    Twitter / X
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <div className={cx(s.footerBottom)}>
        <Container>
          <div className={s.logoHolder}>
            <picture>
              <source
                media="(max-width: 768px)"
                srcSet="/img/cc_logo-white.png"
              />
              <source
                media="(min-width: 769px)"
                srcSet="/img/cc_logo-white.png"
              />
              <img alt="Cocktail Club | Logo" src="/img/cc_logo-white.png" />
            </picture>
          </div>
          <p>© 2024 Cocktail Club Productions SLU. All rights reserved.</p>
        </Container>
      </div>
      {/*
      {isBrowser &&
        (store.session.get("inApp", false) ||
        store.session.get("declined", isDeclined) ||
        isOpen ? (
          <></>
        ) : inAppChecked ? (
          <div className={cx(s.mobilePopup, isOpen ? s.close : "", "tRad")}>
            <Container>
              <div className={cx(s.mobilePopupContent, "text-center")}>
                <div className={cx(s.mobilePopupLogo)}></div>
                <div
                  className={cx(s.mobilePopupTitle, "Heading__small Bold py18")}
                >
                  Cocktail Club is better in the app
                </div>
                <ButtonLink className="Primary Cyan mb18" href="/download">
                  Download app
                </ButtonLink>
                <p
                  className="Bold"
                  onClick={() => {
                    setIsDeclined(true);
                    store.session("declined", true);
                    setIsOpen(!isOpen);
                  }}
                >
                  Not now
                </p>
              </div>
            </Container>
          </div>
        ) : (
          <></>
        ))}
      */}
    </footer>
  );
};

export default Footer;
