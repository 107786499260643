import * as cx from "classnames/bind";
import { Button, Stars, Input, Loader } from "components";
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useSession } from "hooks";
import { fetcher, hasuraHeadersFromSession } from "utils";
import { gql } from "graphql-request";
import s from "styles/Modal.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "next-i18next";

const ScoreInput = ({ setValue = () => {} }) => {
  const starsHolder = useRef(null);
  const [score, setScore] = useState(1);
  const [hoverScore, setHoverScore] = useState(null);
  const calcScore = (e) => {
    return Math.round(
      0.5 +
        (Math.max(0, Math.min(e.layerX / starsHolder.current.offsetWidth, 1)) *
          100) /
          20,
    );
  };
  useEffect(() => {
    if (starsHolder.current) {
      const click = starsHolder.current.addEventListener("click", (e) => {
        setHoverScore(null);
        const score = calcScore(e);
        setScore(score);
        setValue(score);
      });
      const mousemove = starsHolder.current.addEventListener(
        "mousemove",
        (e) => {
          setHoverScore(calcScore(e));
        },
      );
      const mouseleave = starsHolder.current.addEventListener(
        "mouseleave",
        (e) => {
          setHoverScore(null);
        },
      );
      return () => {
        if (starsHolder.current) {
          starsHolder.current.removeEventListener("click", click);
          starsHolder.current.removeEventListener("mousemove", mousemove);
          starsHolder.current.removeEventListener("mouseleave", mouseleave);
        }
      };
    }
  }, [starsHolder]);
  return (
    <>
      <Stars
        score={hoverScore || score}
        scoreCount
        className={cx(s.starsInput, "input")}
        holderRef={starsHolder}
      />
    </>
  );
};

const CreateReviewModal = ({
  item = {},
  type = "cocktail",
  show,
  onClose,
  title,
  className,
}) => {
  const { t } = useTranslation();
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful, isDirty },
  } = useForm();

  const onSubmit = async ({ comment, ...formData }) => {
    const data = {
      ...formData,
      comment: comment ? comment : null,
      item_id: item.id,
      user_id: user.id,
    };
    const headers = hasuraHeadersFromSession(session);
    const query = gql`
      mutation insertReview(
        $user_id: uuid!
        $comment: String
        $score: Int!
        $item_id: Int!
        $hide_name: Boolean!
      ) {
        insert_${type}_reviews(
          objects: {
            user_id: $user_id
            score: $score
            hide_name: $hide_name
            ${type}_id: $item_id
            comment: $comment
          }
        ) {
          returning {
            id
          }
        }
      }
    `;

    await fetcher(query, data, headers);
  };

  const { data: session } = useSession();
  const user = session?.user;

  const modalContent = show ? (
    <div className={cx(s.Modal, s.CreateListModal)}>
      <div className={cx(s.ModalOverlay)} onClick={handleCloseClick}></div>
      <div className={cx(s.ModalContent, className)}>
        <div className={cx("ModalHeader", s.ModalHeader)}>
          <div className={cx(s.ModalTitle)}>
            {t("Add review")} {title && " - " + title}
          </div>
          <a href="#" title={t("Close")} onClick={handleCloseClick}>
            <img alt={t("Close")} src="/img/icons/close.svg" />
          </a>
        </div>
        <div className={cx(s.ModalBody)}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isSubmitting && <Loader />}
            {isSubmitSuccessful ? (
              <div className="text-center">
                <p className="Heading__sub Bold mb12">
                  {t("Thank you for your review!")}
                </p>
                <p>
                  {t("Your review will be visible in a couple of minutes.")}
                </p>
                <div className="ButtonGroup flex">
                  <Button
                    onClick={handleCloseClick}
                    className="Primary Hollow Cancel Full"
                  >
                    {t("Close review")}
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="inputContainer">
                  <label>{t("What is your rate?")}</label>
                  <Controller
                    name="score"
                    control={control}
                    defaultValue={1}
                    render={({ field }) => (
                      <ScoreInput
                        field={field}
                        errors={errors}
                        label={t("Score")}
                        setValue={(v) => {
                          setValue("score", v);
                          clearErrors("score");
                          field.onChange(v);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="inputContainer">
                  <label>
                    {t("Please share your opinion about the cocktail")}
                  </label>
                  <textarea
                    className="RadS"
                    {...register("comment")}
                    type="text"
                    placeholder={t(
                      "Give your review an explanatory description",
                    )}
                  />
                </div>
                <div className="checkContainer">
                  <input
                    id="hide_name"
                    type="checkbox"
                    {...register("hide_name")}
                  />
                  <label htmlFor="hide_name">
                    {t(
                      "Stay anonymous (only first name is displayed by default)",
                    )}
                  </label>
                </div>
                <hr />
                <div className="ButtonGroup flex">
                  <Button
                    onClick={handleCloseClick}
                    className="Primary Hollow Cancel"
                  >
                    {t("Cancel")}
                  </Button>
                  <input
                    type="submit"
                    className="Button Primary Rad flex-grow"
                    value={t("Send review")}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root"),
    );
  } else {
    return null;
  }
};

export default CreateReviewModal;
