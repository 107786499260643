import * as cx from "classnames/bind";
import { Button, Icon } from "components";
import { useRecipeLists } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import s from "styles/Modal.module.scss";
import { fetcher } from "utils";
import { useTranslation } from "next-i18next";
import { gql } from "graphql-request";
import urlSlug from "url-slug";
import useSWR from "swr";

const useBarMenus = (user, headers, organization, hotel, bar) => {
  const variables = {};
  const query = user.organizationManager
    ? gql`
            query {
                menus:bar_menus(where: {_or: [{organization_id: {_eq: ${organization.id}}}, {hotel: {organization_id: {_eq: ${organization.id}}}}, {bar: {organization_id: {_eq: ${organization.id}}}}, {hotel: {organization_id: {_eq: ${organization.id}}}}]}, order_by: {name: asc}) {
                    id
                    name
                    cocktails {
                        cocktail_id
                    }
                    organization_id
                    hotel_id
                    bar_id
                }
            }`
    : user.isManagingBar && bar
    ? gql`
            query {
                menus:bar_menus(where: {_or: [{bar_id: {_eq: ${bar.id}}}]}, order_by: {name: asc}) {
                    id
                    name
                    cocktails {
                        cocktail_id
                    }
                    organization_id
                    hotel_id
                    bar_id
                }
            }`
    : "";
  const { data, mutate } = useSWR(query, (query) =>
    fetcher(query, variables, headers),
  );
  const loading = !!data;
  const menus = (data || { menus: null }).menus || [];
  const addToMenu = async (menu, cocktail) => {
    const newMenus = menus.map((m) =>
      m.id == menu.id
        ? {
            ...menu,
            cocktails: menu.cocktails.concat([{ cocktail_id: cocktail.id }]),
          }
        : menu,
    );
    const query = gql`
        mutation  {
            insert_bar_menu_cocktails_one(object: {bar_menu_id: ${menu.id}, cocktail_id: ${cocktail.id}}) {
                id
            }
        }`;
    await fetcher(query, {}, headers);
    mutate();
  };
  const addToNewMenu = async (name, cocktail) => {
    let query = gql`
            mutation  {
                menu:insert_bar_menus_one(object: {name: "${name}", slug: "${urlSlug(
                  name,
                )}", `;
    query += bar
      ? `bar_id: ${bar.id}`
      : hotel
      ? `hotel_id: ${hotel.id}`
      : `organization_id: ${organization.id}`;
    query += `
                    }) {
                    id
                    name
                    slug
                    cocktails {
                        cocktail_id
                    }
                }
            }`;
    const { menu } = await fetcher(query, {}, headers);
    await addToMenu(menu, cocktail);
  };

  const removeFromMenu = async (menu, cocktail) => {
    let query = gql`

      mutation {
          delete_bar_menu_cocktails(where: {cocktail_id: {_eq: ${cocktail.id}}, bar_menu_id: {_eq: ${menu.id}}}) {
              returning {
                  id
              }
          }
      }
            `;
    await fetcher(query, {}, headers);
    mutate();
  };

  const setRecipeLists = async (newLists) => {
    mutate({ ...data, lists: newLists });
    const query = gql`
        mutation upsert_wishlist($user_id: uuid, $lists: jsonb) {
            insert_wishlists(objects: {user_id: $user_id, lists: $lists}, on_conflict: {constraint: wishlists_pkey, update_columns: lists, where: {user_id: {_eq: "${session.id}"}}}) {
                returning {
                    lists
                }
            }
        }`;
    const response = await fetcher(
      query,
      { user_id: session.id, lists: newLists },
      hasuraHeadersFromSession(session),
    );
    mutate({ ...data, lists: response.insert_wishlists.returning.lists });
  };
  const removeRecipeList = async (list) => {
    await setRecipeLists(recipeLists.filter((l) => l.name != list.name));
  };
  /*
  const { userSession, loading, mutate } = useUserSession();
  console.info(hasuraHeadersFromSession(session));

  const recipeLists = userSession.recipeLists || [];
  */
  return {
    menus,
    loading,
    addToMenu,
    addToNewMenu,
    removeFromMenu,
    setRecipeLists,
    removeRecipeList,
  };
};

const HorecaMenuModal = ({
  cocktail,
  headers,
  user,
  onClose,
  className,
  organization,
  hotel,
  bar,
}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [isBrowser, setIsBrowser] = useState(false);
  const [listName, setListName] = useState("");

  const show = !!cocktail;
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {}, [cocktail]);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const { menus, addToMenu, addToNewMenu, removeFromMenu } = useBarMenus(
    user,
    headers,
    organization,
    hotel,
    bar,
  );

  useEffect(() => {
    setDisabled(!listName || menus.find((list) => list.name == listName));
  }, [menus, listName]);

  const createList = async () => {
    const newLists = recipeLists.concat([{ name: listName, items: [] }]);
    await setRecipeLists(newLists);
    setListName("");
    onClose();
  };

  const modalContent = show ? (
    <div className={cx(s.Modal, s.CreateListModal)}>
      <div className={cx(s.ModalOverlay)} onClick={handleCloseClick}></div>
      <div className={cx(s.ModalContent, className)}>
        <div className={cx("ModalHeader", s.ModalHeader)}>
          <div className={cx(s.ModalTitle)}>{t("Add cocktail to menu")}</div>
          <a href="#" onClick={handleCloseClick}>
            <img alt="" src="/img/icons/close.svg" />
          </a>
        </div>
        <div className={cx(s.ModalBody)}>
          <div className={cx("menuList", s.menuList)}>
            <label>{t("Select menu")}:</label>
            <ul>
              {menus.map((menu) => {
                const added = !!!menu.cocktails.find(
                  (o) => o.cocktail_id == cocktail.id,
                );
                return (
                  <li key={menu.id}>
                    <span>{menu.name}</span>
                    {added ? (
                      <button
                        className="RadA"
                        onClick={() => addToMenu(menu, cocktail)}
                      >
                        ADD TO MENU
                      </button>
                    ) : (
                      <>
                        <button
                          className={cx(s.Added, "RadA")}
                          onClick={() => removeFromMenu(menu, cocktail)}
                        >
                          ADDED <Icon svg="check" size="far" />
                        </button>
                        <button
                          className={cx(s.Remove, "RadA")}
                          onClick={() => removeFromMenu(menu, cocktail)}
                        >
                          <Icon svg="trash-alt" size="far" />
                        </button>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="or-divider">{t("OR")}</div>
          <div className="inputContainer">
            <label>{t("Create new menu")}:</label>
            <input
              type="text"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              placeholder={t("Give your menu a unique name")}
              className="RadA"
            />
          </div>
          <div className="ButtonGroup flex">
            <input
              disabled={disabled}
              type="submit"
              className={cx("Button Primary Gold Rad flex-grow RadA")}
              value={t("Add to new menu")}
              onClick={() => {
                addToNewMenu(listName, cocktail);
              }}
            />

            <Button
              onClick={handleCloseClick}
              className="Primary Hollow Cancel RadA"
            >
              {t("Close")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root"),
    );
  } else {
    return null;
  }
};

export default HorecaMenuModal;
