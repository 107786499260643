import camelCase from "lodash/camelCase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCocktail as fasCocktail,
  faMemo as fasMemo,
  faWineBottle as fasWineBottle,
  faTimes as fasTimes,
  faTrashAlt as fasTrashAlt,
  faCog as fasCog,
  faPowerOff as fasPowerOff,
  faSort as fasSort,
  faSortUp as fasSortUp,
  faSortDown as fasSortDown,
  faUser as fasUser,
  faUserGroup as fasUserGroup,
  faUserCircle as fasUserCircle,
  faChevronUp as fasChevronUp,
  faChevronRight as fasChevronRight,
  faChevronsRight as fasChevronsRight,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronsLeft as fasChevronsLeft,
  faHeart as fasHeart,
  faSearch as fasSearch,
  faShoppingCart as fasShoppingCart,
  faCamera as fasCamera,
  faThumbsDown as fasThumbsDown,
  faThumbsUp as fasThumbsUp,
  faSlidersH as fasSlidersH,
  faPlay as fasPlay,
  faBoxCheck as fasBoxCheck,
  faBadgePercent as fasBadgePercent,
  faBagShopping as fasBagShopping,
  faChartSimple as fasChartSimple,
  faStars as fasStars,
  faFolderArrowUp as fasFolderArrowUp,
  faArrowRotateLeft as fasArrowRotateLeft,
  faImage as fasImage,
  faCartShopping as fasCartShopping,
  faEnvelope as fasEnvelope,
  faSpinner as fasSpinner,
  faPenToSquare as fasPenToSquare,
  faEye as fasEye,
  faCheck as fasCheck,
  faCopy as fasCopy,
  faCircleNotch as fasCircleNotch,
  faSitemap as fasSitemap,
  faHotel as fasHotel,
  faShare as fasShare,
  faPrint as fasPrint,
  faListCheck as fasListCheck,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faCocktail as farCocktail,
  faMemo as farMemo,
  faWineBottle as farWineBottle,
  faTimes as farTimes,
  faTrashAlt as farTrashAlt,
  faCog as farCog,
  faPowerOff as farPowerOff,
  faSort as farSort,
  faSortUp as farSortUp,
  faSortDown as farSortDown,
  faUser as farUser,
  faUserGroup as farUserGroup,
  faUserCircle as farUserCircle,
  faChevronUp as farChevronUp,
  faChevronRight as farChevronRight,
  faChevronsRight as farChevronsRight,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronsLeft as farChevronsLeft,
  faHeart as farHeart,
  faSearch as farSearch,
  faShoppingCart as farShoppingCart,
  faCamera as farCamera,
  faThumbsDown as farThumbsDown,
  faThumbsUp as farThumbsUp,
  faSlidersH as farSlidersH,
  faPlay as farPlay,
  faBoxCheck as farBoxCheck,
  faBadgePercent as farBadgePercent,
  faBagShopping as farBagShopping,
  faChartSimple as farChartSimple,
  faStars as farStars,
  faFolderArrowUp as farFolderArrowUp,
  faArrowRotateLeft as farArrowRotateLeft,
  faImage as farImage,
  faCartShopping as farCartShopping,
  faEnvelope as farEnvelope,
  faSpinner as farSpinner,
  faPenToSquare as farPenToSquare,
  faEye as farEye,
  faCheck as farCheck,
  faCopy as farCopy,
  faCircleNotch as farCircleNotch,
  faSitemap as farSitemap,
  faHotel as farHotel,
  faShare as farShare,
  faPrint as farPrint,
  faListCheck as farListCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCocktail as falCocktail,
  faMemo as falMemo,
  faWineBottle as falWineBottle,
  faTimes as falTimes,
  faTrashAlt as falTrashAlt,
  faCog as falCog,
  faPowerOff as falPowerOff,
  faSort as falSort,
  faSortUp as falSortUp,
  faSortDown as falSortDown,
  faUser as falUser,
  faUserGroup as falUserGroup,
  faUserCircle as falUserCircle,
  faChevronUp as falChevronUp,
  faChevronRight as falChevronRight,
  faChevronsRight as falChevronsRight,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronsLeft as falChevronsLeft,
  faHeart as falHeart,
  faSearch as falSearch,
  faShoppingCart as falShoppingCart,
  faCamera as falCamera,
  faThumbsDown as falThumbsDown,
  faThumbsUp as falThumbsUp,
  faSlidersH as falSlidersH,
  faPlay as falPlay,
  faBoxCheck as falBoxCheck,
  faBadgePercent as falBadgePercent,
  faBagShopping as falBagShopping,
  faChartSimple as falChartSimple,
  faStars as falStars,
  faFolderArrowUp as falFolderArrowUp,
  faArrowRotateLeft as falArrowRotateLeft,
  faImage as falImage,
  faCartShopping as falCartShopping,
  faEnvelope as falEnvelope,
  faSpinner as falSpinner,
  faPenToSquare as falPenToSquare,
  faEye as falEye,
  faCheck as falCheck,
  faCopy as falCopy,
  faCircleNotch as falCircleNotch,
  faSitemap as falSitemap,
  faHotel as falHotel,
  faShare as falShare,
  faPrint as falPrint,
  faListCheck as falListCheck,
} from "@fortawesome/pro-light-svg-icons";

const { library } = require("@fortawesome/fontawesome-svg-core");

library.add(
  fasCocktail,
  farCocktail,
  falCocktail,
  fasMemo,
  farMemo,
  falMemo,
  fasWineBottle,
  farWineBottle,
  falWineBottle,
  fasTimes,
  farTimes,
  falTimes,
  fasTrashAlt,
  farTrashAlt,
  falTrashAlt,
  fasCog,
  farCog,
  falCog,
  fasPowerOff,
  farPowerOff,
  falPowerOff,
  fasSort,
  fasSortUp,
  fasSortDown,
  farSort,
  farSortUp,
  farSortDown,
  falSort,
  falSortUp,
  falSortDown,
  fasUser,
  farUser,
  falUser,
  fasUserGroup,
  farUserGroup,
  falUserGroup,
  fasUserCircle,
  farUserCircle,
  falUserCircle,
  fasChevronUp,
  farChevronUp,
  falChevronUp,
  fasChevronRight,
  farChevronRight,
  falChevronRight,
  fasChevronsRight,
  farChevronsRight,
  falChevronsRight,
  fasChevronDown,
  farChevronDown,
  falChevronDown,
  fasChevronLeft,
  farChevronLeft,
  falChevronLeft,
  fasChevronsLeft,
  farChevronsLeft,
  falChevronsLeft,
  fasHeart,
  farHeart,
  falHeart,
  fasSearch,
  farSearch,
  falSearch,
  fasShoppingCart,
  farShoppingCart,
  falShoppingCart,
  fasCamera,
  farCamera,
  falCamera,
  fasThumbsDown,
  farThumbsDown,
  falThumbsDown,
  fasThumbsUp,
  farThumbsUp,
  falThumbsUp,
  fasSlidersH,
  farSlidersH,
  falSlidersH,
  fasPlay,
  farPlay,
  falPlay,
  fasBoxCheck,
  farBoxCheck,
  falBoxCheck,
  fasBadgePercent,
  farBadgePercent,
  falBadgePercent,
  fasBagShopping,
  farBagShopping,
  falBagShopping,
  fasChartSimple,
  farChartSimple,
  falChartSimple,
  fasStars,
  farStars,
  falStars,
  fasFolderArrowUp,
  farFolderArrowUp,
  falFolderArrowUp,
  fasArrowRotateLeft,
  farArrowRotateLeft,
  falArrowRotateLeft,
  fasImage,
  farImage,
  falImage,
  fasCartShopping,
  farCartShopping,
  falCartShopping,
  fasEnvelope,
  farEnvelope,
  falEnvelope,
  fasSpinner,
  farSpinner,
  falSpinner,
  fasPenToSquare,
  farPenToSquare,
  falPenToSquare,
  fasEye,
  farEye,
  falEye,
  fasCheck,
  farCheck,
  falCheck,
  fasCopy,
  farCopy,
  falCopy,
  fasCircleNotch,
  farCircleNotch,
  falCircleNotch,
  fasSitemap,
  farSitemap,
  falSitemap,
  fasHotel,
  farHotel,
  falHotel,
  fasShare,
  farShare,
  falShare,
  fasPrint,
  farPrint,
  falPrint,
  fasListCheck,
  farListCheck,
  falListCheck,
);

/*
const lookup = {
  far: "regular",
  fas: "solid",
  fal: "light",
};
*/

const Icon = ({ size = "far", svg, ...props }) => {
  /*
  const name = camelCase(`fa-${svg}`);
  const module = `@fortawesome/pro-${
    lookup[size] || "regular"
  }-svg-icons/${name}`;
  console.info(module);
  console.info(import(module));
   */
  return <FontAwesomeIcon icon={[size, svg]} {...props} />;
};

export default Icon;
