import ReactSlider from "react-slider";
import InputContainer from "./InputContainer";
import * as cx from "classnames/bind";
import s from "styles/Input.module.scss";

const Thumb = (props, state) => {
  return <div {...props}>{state.valueNow}</div>;
};

const Range = ({ field, ...props }) => {
  return (
    <InputContainer {...props} {...field}>
      <ReactSlider
        className={cx(s.horizontalSlider)}
        thumbClassName={cx(s.horizontalSliderThumb)}
        trackClassName={cx(s.horizontalSliderTrack)}
        renderThumb={Thumb}
        {...field}
        {...props}
      />
    </InputContainer>
  );
};

export default Range;
