import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";

const Section = ({ id, className, children, ...other }) => (
  <section id={id} className={cx("Section", s.Section, className)} {...other}>
    {children}
  </section>
);

export default Section;
