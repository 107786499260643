import * as cx from "classnames/bind";
import s from "styles/Container.module.scss";
import { useLayoutEffect, useEffect, useState, useRef } from "react";

const ScrollX = ({ children, cover, fade, className, ...other }) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [intersections, setIntersections] = useState({
    left: true,
    right: false,
  });
  const scrollRef = useRef(null);

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    if (window && scrollRef.current) {
      let observer;
      const childNodes = scrollRef.current.childNodes;
      if (childNodes.length) {
        const leftMost = childNodes[0];
        const rightMost = childNodes[childNodes.length - 1];
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              let elem = entry.target;
              const key = elem === leftMost ? "left" : "right";
              const isIntersecting = entry.isIntersecting; // && entry.intersectionRatio > 0.5
              setIntersections({
                ...intersections,
                [key]: isIntersecting,
              });
            });
          },
          {
            root: scrollRef.current,
            rootMargin: "0px",
            threshold: 1.0,
          },
        );
        observer.observe(leftMost);
        observer.observe(rightMost);
      }
      const resizeListener = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener("resize", resizeListener);
      const scrollListener = () => {
        setScrollOffset(scrollRef.current.scrollLeft);
      };
      scrollRef.current.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("resize", resizeListener);
        scrollRef.current &&
          scrollRef.current.removeEventListener("scroll", scrollListener);
        observer && observer.disconnect();
      };
    }
  }, [scrollRef.current]);

  //Trigger resize on first load
  useEffect(() => {
    if (window) {
      window.dispatchEvent(new Event("resize"));
    }
  }, []);

  const innerWidth = scrollRef.current?.children
    ? [...(scrollRef.current?.children || [])]
        .map((child) => child.offsetWidth)
        .reduce((acc, w) => acc + w, 0)
    : 0;

  const contentWidth = Math.min(1260, windowWidth);

  const overflow = innerWidth > contentWidth;

  const scrollLeftOverflow = scrollOffset > contentWidth / 12;
  const scrollRightOverflow =
    scrollOffset > innerWidth - (windowWidth + contentWidth) / 2;

  /*
  console.log("contentWidth", contentWidth);
  console.log("windowWidth", windowWidth);
  console.log("innerWidth", innerWidth);
  console.log("scrollOfset", scrollOffset);
  console.log("overflow", innerWidth - (windowWidth + contentWidth) / 2);
  console.info(intersections);
    */

  return (
    <div
      className={cx(
        "scrollParent",
        s.scrollParent,
        cover ? "cover" : "",
        fade ? "fade" : "",
      )}
      {...other}
    >
      <div className="scrollBtnHolder">
        <div
          onClick={() => scroll(-contentWidth / 2)}
          className={cx(
            "scrollBtn scrollLeft",
            overflow ? "show" : "hide",
            scrollLeftOverflow ? "show2" : "hide2",
          )}
        ></div>
        <div
          onClick={() => scroll(contentWidth / 2)}
          className={cx(
            "scrollBtn scrollRight",
            overflow ? "show" : "hide",
            scrollRightOverflow ? "hide2" : "show2",
          )}
        ></div>
      </div>
      <div
        ref={scrollRef}
        className={cx("row Scroll fullWidth", s.scrollRef, className)}
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollX;
