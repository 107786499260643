import { useTranslation } from "next-i18next";
import { imageUrl } from "utils";
import { useUploadForm } from "hooks/useUploadForm";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Icon from "../Icon";

import * as cx from "classnames/bind";
import i from "styles/Input.module.scss";

const ImageUpload = ({ value, type, ...props }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    originalValue: value,
    previewUrl: imageUrl(value, "/img/empty.png"),
  });

  const { setValue, trigger } = useFormContext();

  const { isSuccess, isLoading, uploadForm, progress } = useUploadForm(
    `${process.env.NEXT_PUBLIC_REST_API_URL}${type}/image`,
  );

  const resetValue = () => {
    setValue(props.name, state.originalValue);
    setState({
      ...state,
      previewUrl: imageUrl(state.originalValue, "/img/empty.png"),
    });
    trigger("image");
  };
  const handleImageChange = async (e) => {
    const [file] = e.target.files;
    if (file) {
      setState({ ...state, previewUrl: URL.createObjectURL(file) });
      const formData = new FormData();
      formData.append("image", file);
      setValue(props.name, await uploadForm(formData));
      trigger("image");
    }
  };
  return (
    <div className={cx(i.InputContainer)}>
      <div className={cx(i.singleImageUpload)}>
        <label>Image</label>
        <div className={cx(i.singleImageUpload_container)}>
          {isLoading && (
            <div
              className={cx(i.singleImageUpload_loader)}
              style={{ width: `${Math.min(progress, 100)}%` }}
            />
          )}
          <div className={cx(i.singleImageUpload_image)}>
            <img width="300" src={state.previewUrl} />
          </div>
          <div className={cx(i.singleImageUpload_actions)}>
            <span>
              <label>
                <Icon size="far" svg="folder-arrow-up" />
                <input
                  accept="image/*"
                  onChange={handleImageChange}
                  type="file"
                  hidden
                />
              </label>
            </span>
            <span>
              <label>
                <Icon
                  size="far"
                  svg="arrow-rotate-left"
                  onClick={() => resetValue()}
                />
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
