import { fetcher } from "utils";
import isEmpty from "lodash.isempty";

const emptyResponse = {
  notFound: true,
};
export const runQuery = async (query, params = {}, filter = {}) => {
  let m = null;
  let key = params.key;
  switch (query) {
    case "cocktails/single":
      m = (await import(`./${query}.js`)).default;
      key = key || "cocktail";
      break;
    case "cocktails/multiple":
      m = (await import(`./${query}.js`)).default;
      key = key || "cocktails";
      break;
    case "orders/single":
      m = (await import(`./${query}.js`)).default;
      key = key || "order";
      break;
    default:
      try {
        m = (await import(`./${query}.js`)).default;
      } catch {
        m = { queryFn: () => query };
      }
      break;
  }
  if (!m) {
    return emptyResponse;
  }
  try {
    const data = await fetcher(
      (m.queryFn || m)(params, filter),
      null,
      params.headers
    );
    const transformer = m.transformer || ((v) => v);
    let transformed;
    if (transformer.constructor.name !== "AsyncFunction") {
      transformed = key
        ? Object.fromEntries(
            Object.entries(data).map((o) => {
              const [k, v] = o;
              return [k, k === key ? transformer(v) : v];
            })
          )
        : transformer(data);
    } else {
      transformed = key
        ? Object.fromEntries(
            await Promise.all(
              Object.entries(data).map(async (o) => {
                const [k, v] = o;
                return [k, k === key ? await transformer(v) : v];
              })
            )
          )
        : await transformer(data);
    }
    return Promise.resolve(
      isEmpty(data) || isEmpty(transformed)
        ? emptyResponse
        : { props: transformed }
    );
  } catch (e) {
    console.info(e);
    return emptyResponse;
  }
};
