import React from "react";
import { Range, getTrackBackground } from "react-range";
import { useState } from "react";
import * as cx from "classnames/bind";
import s from "styles/Input.module.scss";

const Slider = ({}) => {
  const STEP = 1;
  const MIN = 0;
  const MAX = 100;
  const [values, setValues] = React.useState([50]);
  return (
    <div className={cx(s.SliderInput)}>
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => setValues(values)}
        renderTrack={({ className, props, children }) => (
          <div
            className={cx(s.SliderInputTrack)}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{ ...props.style }}
          >
            <div
              className={cx(s.SliderInputFill)}
              ref={props.ref}
              style={{
                background: `linear-gradient(to right, #3f5ea9 0%, #4a90e2 ${values}%, transparent ${values}%, transparent 100%)`,
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            className={cx(s.SliderInputThumb)}
            {...props}
            style={{ ...props.style }}
          >
            <div>{values[0].toFixed(1)}</div>
          </div>
        )}
      />
    </div>
  );
};
export default Slider;
