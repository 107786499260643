import React from "react";
import { useRef, useEffect } from "react";
import * as cx from "classnames/bind";
import s from "styles/Input.module.scss";
import { motion, useAnimation } from "framer-motion";

import { InfoPop } from "components";

const InputContainer = React.forwardRef(
  (
    {
      label,
      name,
      type,
      value,
      infoTitle,
      infoContent,
      infoPos,
      className,
      errors = {},
      field,
      children,
    },
    ref,
  ) => {
    const controls = useAnimation();
    const error = errors[name];
    const elRef = useRef(null);
    //console.info(name, !!error && elRef.current === document.activeElement);
    const el = elRef.current
      ? elRef.current.select?.inputRef
        ? elRef.current.select.inputRef
        : elRef.current
      : null;
    useEffect(() => {
      if (!!error && el === document.activeElement) {
        controls.start({
          x: [10, -10, 6, -6, 2, 0],
          transition: { duration: 0.4 },
        });
      }
    }, [error]);

    return (
      <div
        className={cx("InputContainer", s.InputContainer, className, {
          [s.error]: !!error,
        })}
      >
        {infoTitle && infoContent && (
          <InfoPop title={infoTitle} position={infoPos}>
            {infoContent}
          </InfoPop>
        )}
        <motion.div
          className={cx(s.inputChild, "inputChild")}
          animate={controls}
        >
          {React.cloneElement(children, {
            ref: (e) => {
              ref(e);
              elRef.current = e;
            },
          })}
          {label && (
            <label
              htmlFor={name}
              className={cx("InputHeadline", s.InputHeadline)}
            >
              {label}
            </label>
          )}
        </motion.div>
        <div className={cx("errorMsg", s.errorMsg)}>
          {error && error?.message && error.message}
        </div>
      </div>
    );
  },
);

export default InputContainer;
