import { useRouter } from "next/router";
import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import { useState } from "react";
import { NavLink, Icon, UserMenu } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideMenu = ({ label, className, children, ...other }) => {
  const router = useRouter();

  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };
  const isActive = (path) => {
    return router.pathname.includes(path);
  };
  return (
    <div className={cx("sideMenu", s.sideMenu)}>
      <div
        className={cx("menuTrigger", s.menuTrigger, active ? "" : s.closed)}
        onClick={handleClick}
      >
        <span />
      </div>
      <div className={s.logoHolder}>
        <picture>
          <source media="(max-width: 768px)" srcSet="/img/cc_logo-white.png" />
          <source media="(min-width: 769px)" srcSet="/img/cc_logo-white.png" />
          <img alt="Cocktail Club logo symbol" src="/img/cc_logo-white.png" />
        </picture>
      </div>
      <div className={cx(s.menuHolder, active ? "" : s.closed)}>
        <ul className={cx("mainMenu", s.mainMenu)}>
          <li className={isActive("/products") ? s.active : ""}>
            <NavLink href="/admin/products">
              <a>
                <Icon size="far" svg="wine-bottle" />
                Products
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              <li>
                <NavLink href="/admin/products/create">
                  <a onClick={handleClick}>Add new product</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/admin/products">
                  <a onClick={handleClick}>All products</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/admin/products/import">
                  <a onClick={handleClick}>Import products</a>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={isActive("/cocktails") ? s.active : ""}>
            <NavLink href="/admin/cocktails">
              <a onClick={handleClick}>
                <Icon size="far" svg="cocktail" />
                Cocktails
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              <li>
                <NavLink href="/admin/cocktails/create">
                  <a onClick={handleClick}>Add new cocktail</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/admin/cocktails">
                  <a onClick={handleClick}>All cocktails</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/admin/cocktails/categories">
                  <a onClick={handleClick}>Categories</a>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={isActive("/orders") ? s.active : ""}>
            <NavLink href="/admin/orders">
              <a onClick={handleClick}>
                <Icon size="far" svg="bag-shopping" />
                Orders
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              <li>
                <NavLink href="/admin/orders">
                  <a onClick={handleClick}>All orders</a>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={isActive("/pages") ? s.active : ""}>
            <NavLink href="/admin/pages">
              <a onClick={handleClick}>
                <Icon size="far" svg="memo" />
                Pages
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              <li>
                <NavLink href="/admin/pages/create">
                  <a onClick={handleClick}>Add new page</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/admin/pages">
                  <a onClick={handleClick}>All pages</a>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={isActive("/articles") ? s.active : ""}>
            <NavLink href="/admin/articles">
              <a onClick={handleClick}>
                <Icon size="far" svg="memo" />
                Articles
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              <li>
                <NavLink href="/admin/articles/create">
                  <a onClick={handleClick}>Add new article</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/admin/articles">
                  <a onClick={handleClick}>All articles</a>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={isActive("/users") ? s.active : ""}>
            <NavLink href="/admin/users">
              <a onClick={handleClick}>
                <Icon size="far" svg="user-group" />
                Users
              </a>
            </NavLink>
          </li>
          <li className={isActive("/ambassadors") ? s.active : ""}>
            <NavLink href="/admin/ambassadors">
              <a onClick={handleClick}>
                <Icon size="far" svg="user-group" />
                Ambassadors
              </a>
            </NavLink>
          </li>
          <li>
            <NavLink href="/admin/analytics">
              <a target="_blank" onClick={handleClick}>
                <Icon size="far" svg="chart-simple" />
                Analytics
              </a>
            </NavLink>
          </li>
        </ul>
        <UserMenu />
      </div>
    </div>
  );
};

export default SideMenu;
