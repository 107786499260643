import * as cx from "classnames/bind";
import s from "styles/Loader.module.scss";
const Spinner = ({ fixed, absolute }) => {
  return (
    <div
      className={cx(
        s.DotSpinner,
        fixed ? s.Fixed : "",
        absolute ? s.Absolute : "",
      )}
    >
      Loading...
    </div>
  );
};

export default Spinner;
