import dynamic from "next/dynamic";
import Script from "next/script";
import * as cx from "classnames/bind";
import s from "styles/Wistia.module.scss";

const Wistia = ({ code, size, className, ...other }) => (
  <div className={cx(s.wistiaDisplay, className, "wistiaDisplay")} {...other}>
    <Script
      src={"https://fast.wistia.com/embed/medias/" + code + ".jsonp"}
      async
    ></Script>
    <Script
      src="https://fast.wistia.com/assets/external/E-v1.js"
      async
    ></Script>
    <div
      className="wistia_responsive_padding"
      style={{ padding: `${size}`, position: "relative" }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          className={"wistia_embed wistia_async_" + code + " videoFoam=true"}
          style={{ height: "100%", position: "relative", width: "100%" }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
);

export default Wistia;
