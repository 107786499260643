import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import Link from "next/link";

const AdminContent = ({ children, title, ...other }) => (
  <div className={cx("AdminContent", s.AdminContent)}>
    <div className={cx("contentInner", s.contentInner)}>
      <div className={cx("contentHeader", s.contentHeader)}>
        <h1>{title}</h1>
      </div>

      {children}
    </div>
  </div>
);

export default AdminContent;
