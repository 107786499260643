import { Button, Spinner } from "components";
import * as cx from "classnames/bind";

const SubmitButton = ({
  isSubmitting,
  disabled,
  className,
  children,
  ...props
}) => {
  return (
    <Button
      className={cx("Primary SubmitButton", className, {
        Disabled: disabled,
        Submitting: isSubmitting,
      })}
      disabled={disabled}
      {...props}
    >
      {!isSubmitting && children}
      {isSubmitting && <Spinner style={{ color: "#fff" }} />}
    </Button>
  );
};

export default SubmitButton;
