import * as cx from "classnames/bind";
import s from "styles/Accordion.module.scss";

import { useState } from "react";

export default function Accordion(props) {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  return (
    <div className={cx("Accordion", s.Accordion, isShowing ? s.open : "")}>
      <button onClick={toggle} type="button">
        {props.title}
      </button>
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
      </div>
    </div>
  );
}
