import * as cx from "classnames/bind";
import { Icon } from "components";
import Link from "next/link";
import s from "styles/Button.module.scss";

const ButtonLink = ({
  href,
  className,
  leftIcon,
  children,
  size,
  svg,
  ...other
}) => (
  <Link
    href={href}
    className={cx(
      "Button ButtonLink",
      s.Button,
      className,
      leftIcon ? "leftIcon" : "",
    )}
    {...other}
  >
    {svg && leftIcon && <Icon size={size} svg={svg} />}
    {children}
    {svg && !leftIcon && <Icon size={size} svg={svg} />}
  </Link>
);

export default ButtonLink;
