//import Upload from "./atoms/Dropzone";
import dynamic from "next/dynamic";

import Accordion from "./atoms/Accordion";
import AdminContent from "./atoms/AdminContent";
import AdminSection from "./atoms/AdminSection";
import Button from "./atoms/Button";
import SubmitButton from "./atoms/SubmitButton";
import ButtonLink from "./atoms/ButtonLink";
import Container from "./atoms/Container";
import ScrollX from "./atoms/ScrollX";
import FileButton from "./atoms/FileButton";
import Footer from "./atoms/Footer";
import Bar from "./atoms/Bar";
import HomeContent from "./atoms/HomeContent";
import HorecaContent from "./atoms/HorecaContent";
import Icon from "./atoms/Icon";
import Input from "./atoms/Input/Input";
import ImageUpload from "./atoms/Input/ImageUpload";
import Multi from "./atoms/Input/Multi";
import Slider from "./atoms/Input/Slider";
import Textarea from "./atoms/Input/Textarea";
import Range from "./atoms/Input/Range";
import Loader from "./atoms/Loader";
import Spinner from "./atoms/Spinner";
import NavLink from "./atoms/NavLink";
import Stars from "./atoms/Stars";
import Section from "./atoms/Section";
import Wistia from "./atoms/Wistia";
import HomeMenu from "./menus/HomeMenu";
import ServiceMenu from "./menus/ServiceMenu";
import Z2HMenu from "./menus/Z2HMenu";
import SideMenu from "./menus/SideMenu";
import HorecaMenu from "./menus/HorecaMenu";
import TopMenu from "./menus/TopMenu";
//import DataTable from "./atoms/DataTable";
import UserMenu from "./menus/UserMenu";
import ShareModal from "./modals/ShareModal";
import CreateListModal from "./modals/CreateListModal";
import HorecaMenuModal from "./modals/HorecaMenuModal";
import CreateReviewModal from "./modals/CreateReviewModal";
import LoginModal from "./modals/LoginModal";
import Modal from "./modals/Modal";
import InfoPop from "./popups/InfoPop";
import LegalPopup from "./popups/LegalPopup";
import Popup from "./popups/Popup";
import ServicePopup from "./popups/ServicePopup";
const Upload = dynamic(() => import("./atoms/Dropzone").then((mod) => mod));
const Select = dynamic(() => import("./atoms/Input/Select").then((mod) => mod));
//import Select from "./atoms/Input/Select";
const DataTable = dynamic(
  () => import("./atoms/DataTable").then((mod) => mod),
  { ssr: false },
);
const OrderSummary = dynamic(() =>
  import("./atoms/OrderSummary").then((mod) => mod),
);
const CocktailSelector = dynamic(() =>
  import("./atoms/CocktailSelector").then((mod) => mod),
);
const ProductCarousel = dynamic(() =>
  import("./atoms/Carousel/ProductCarousel").then((mod) => mod),
);
const ToolsCarousel = dynamic(() =>
  import("./atoms/Carousel/ToolsCarousel").then((mod) => mod),
);
const Z2HCarousel = dynamic(() =>
  import("./atoms/Carousel/Z2HCarousel").then((mod) => mod),
);
const GalleryCarousel = dynamic(() =>
  import("./atoms/Carousel/GalleryCarousel").then((mod) => mod),
);

const BarMap = dynamic(() => import("./atoms/BarMap"), {
  ssr: false,
});

const Editor = dynamic(() => import("./atoms/Editor"), {
  ssr: false,
});

const Cocktail = dynamic(() => import("./atoms/Cocktail").then((mod) => mod));
const ArticleCard = dynamic(() =>
  import("./atoms/ArticleCard").then((mod) => mod),
);
const CollectionCard = dynamic(() =>
  import("./atoms/CollectionCard").then((mod) => mod),
);
const Product = dynamic(() => import("./atoms/Product").then((mod) => mod));

export {
  Accordion,
  AdminContent,
  AdminSection,
  Button,
  ButtonLink,
  BarMap,
  Editor,
  Product,
  Cocktail,
  ArticleCard,
  CollectionCard,
  CocktailSelector,
  Container,
  ScrollX,
  ShareModal,
  CreateListModal,
  HorecaMenuModal,
  CreateReviewModal,
  DataTable,
  Wistia,
  FileButton,
  Footer,
  Bar,
  GalleryCarousel,
  HomeContent,
  HorecaContent,
  HomeMenu,
  Icon,
  ImageUpload,
  InfoPop,
  Input,
  LegalPopup,
  Loader,
  Spinner,
  LoginModal,
  Modal,
  Multi,
  NavLink,
  OrderSummary,
  Popup,
  ProductCarousel,
  Range,
  Section,
  Stars,
  Select,
  ServiceMenu,
  Z2HMenu,
  ServicePopup,
  SideMenu,
  HorecaMenu,
  Slider,
  Textarea,
  ToolsCarousel,
  Z2HCarousel,
  TopMenu,
  Upload,
  UserMenu,
  SubmitButton,
};
