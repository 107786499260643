import * as cx from "classnames/bind";
import s from "styles/Loader.module.scss";
const Loader = ({ fixed, relative, color }) => {
  return (
    <div
      className={cx(s.Loader, fixed ? s.Fixed : "", relative ? s.Relative : "")}
    >
      <div className={cx(s.Spinner)} color={color}></div>
    </div>
  );
};

export default Loader;
