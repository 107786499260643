import { gql } from "graphql-request";
export const orderFragment = gql`
            id
            number
            created_at
            status
            cart
            user {
              display_name
              email
            }
            shipping_address {
              id
              address
            }
`;
export const cocktailFragmentWithOutMeta = gql`
            id
            name
            image {
                id
            }
            copied_from
            slug
            description
            translations
            hidden
            pro
            updated_at
            extra_data
            glass {
                id
                name
            }
            flavors(order_by: {flavor: {name: asc}}) {
                value
                flavor {
                    id
                    name
                }
            }
            spirits(order_by: {spirit: {name: asc}}) {
                spirit {
                    id
                    name
                }
            }
            difficulty {
                id
                name
            }
            reviews_aggregate {
              aggregate {
                avg {
                  score
                }
              }
            }
            recipes {
                id
                name
                default
                exclusive_countries
                excluded_countries
                cocktail_id
                timeline {
                    duration
                    video_embed_id
                    steps(order_by: {position: asc}) {
                        id
                        title
                        wistia_id
                        position
                        description
                        translations
                        equipments {
                            equipment {
                                id
                                name
                                slug
                                translations
                                type
                                images {
                                    id
                                }
                            }
                        }
                        ingredients(order_by: {order: asc}) {
                            ingredient {
                                id
                                name
                                slug
                                translations
                                type
                                images {
                                    id
                                }
                                parent_id
                                brand_id
                                is_parent
                            }
                            measurements {
                                metric_amount
                                metric_unit {
                                    id
                                    name
                                    translations
                                }
                                imperial_amount
                                imperial_unit {
                                    id
                                    name
                                    translations
                                }
                                suffix
                                price
                            }
                            order
                        }
                    }
                }
            }
            `;
export const cocktailFragment = gql`
            id
            name
            image {
                id
            }
            slug
            hidden
            pro
            copied_from
            description
            translations
            updated_at
            extra_data
            flavors(order_by: {flavor: {name: asc}}) {
                value
                flavor {
                    name
                    translations
                }
            }
            spirits(order_by: {spirit: {name: asc}}) {
                spirit {
                    id
                    name
                }
            }
            glass {
                id
                name
            }
            difficulty {
                name
            }
            reviews {
                user {
                    display_name
                    first_name
                    last_name
                }
                score
                comment
                hide_name
            }
            reviews_aggregate {
              aggregate {
                avg {
                  score
                }
              }
            }
            recipes {
                id
                name
                default
                exclusive_countries
                excluded_countries
                timeline {
                    duration
                    video_embed_id
                    wistia_meta @include(if: $withMeta)
                    steps(order_by: {position: asc}) {
                        title
                        wistia_id
                        description
                        translations
                        equipments {
                            equipment {
                                id
                                name
                                slug
                                type
                                translations
                                images {
                                    id
                                }
                            }
                        }
                        ingredients(order_by: {order: asc}) {
                            ingredient {
                                id
                                name
                                slug
                                translations
                                type
                                images(order_by: {order: asc}) {
                                    id
                                }
                                parent_id
                                is_parent
                                brand_id
                                extra_data
                                recipe_timeline_step_ingredients_aggregate {
                                    aggregate {
                                        count
                                    }
                                }
                                parent {
                                    name
                                    parent {
                                        name
                                        parent {
                                            name
                                        }
                                    }
                                }
                            }
                            measurements {
                                metric_amount
                                metric_unit {
                                    name
                                    translations
                                }
                                imperial_amount
                                imperial_unit {
                                    name
                                    translations
                                }
                                suffix
                                price
                            }
                            order
                        }
                    }
                }
            }
            `;

export const productFragment = `
            id
            name
            extra_data
            description
            images(order_by: {order: asc}) {
                id
            }
            alcohol_percentage
            brand {
                name
                slug
            }
            country {
                name
                code
                slug
            }
            slug
            reviews {
                user {
                    display_name
                    first_name
                    last_name
                }
                score
                comment
                hide_name
            }
            reviews_aggregate {
              aggregate {
                avg {
                  score
                }
              }
            }
            cocktails {
                id
                name
                image {
                    id
                }
                slug
                description
            }
            parent {
                name
            cocktails {
                id
                name
                image {
                    id
                }
                slug
                description
            }
                parent {
                    name
            cocktails {
                id
                name
                image {
                    id
                }
                slug
                description
            }
                    parent {
                        name
            cocktails {
                id
                name
                image {
                    id
                }
                slug
                description
            }
                        parent {
                            name
            cocktails {
                id
                name
                image {
                    id
                }
                slug
                description
            }
                        }
                    }
                }
            }
        }
`;
export const equipmentFragment = `
    id
    name
    slug
    images {
        id
    }
`;

export const checklistFragment = gql`
    id
    title
    hidden
    description
    bars: bar_checklists(order_by: {bar:{name: asc}}) {
        bar {
            id
            name
        }
    }
    shifts(order_by: {active: desc}) {
        id
        bar_id
        active
    }
    columns(order_by: {order: asc}) {
        id
        title
        order
        rows(order_by: {order: asc}) {
            id
            content
            order
            extra
            states(where: {checklist_shift: {active: {_eq: true}}}, order_by: {checklist_shift_id: desc}, limit: 1) {
                id
                data
                checklist_shift {
                    bar_id
                }
                user {
                    id
                    display_name
                }
            }
        }
    }
`;

export const horecaFragments = gql`
  fragment barFragment on bars {
    id
    name
    slug
    description
    organization {
      id
      name
      slug
    }
    hotel {
      id
      name
      slug
      organization {
        id
        name
        slug
      }
    }
    enabled_menus {
      menu {
        id
        slug
        name
      }
    }
    menus(order_by: { name: desc }) {
      id
      slug
      name
      cocktails(limit: 1) {
        cocktail {
          image_id
        }
      }
    }
  }

  fragment hotelFragment on hotels {
    id
    name
    slug
    organization {
      id
      name
      slug
    }
    bars {
      ...barFragment
    }
  }
  fragment organizationFragment on organizations {
    id
    name
    slug
    bars {
      ...barFragment
    }
    hotels {
      ...hotelFragment
    }
  }
`;

export const horecaUserFragment = gql`
              id
              email
              display_name
              organizations {
                  organization {
                      id
                      slug
                  }
              }
              hotels {
                  hotel {
                      id
                      slug
                  }
              }
              bars {
                  bar {
                      id
                      slug
                      name
                      organization {
                          id
                          slug
                      }
                      hotel {
                          id
                          name
                          slug
                          organization {
                              id
                              name
                              slug
                          }
                      }
                  }
              }
              tended_bars {
                  bar {
                      id
                      name
                      slug
                      organization {
                          id
                          name
                          slug
                      }
                      hotel {
                          id
                          name
                          slug
                          organization {
                              id
                              name
                              slug
                          }
                      }
                  }
              }
`;
