import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import Link from "next/link";
import * as cx from "classnames/bind";
import s from "styles/LegalPopup.module.scss";

const ServicePopup = ({ show, onClose, className }) => {
  return (
    <CookieConsent
      disableStyles
      containerClasses={cx("text-center", s.legalPopup, s.servicePopup)}
      buttonWrapperClasses={cx("ButtonGroup", s.buttonWrapper)}
      buttonClasses={cx("Button", s.acceptServiceButton)}
      contentClasses={cx(s.legalPopupBody)}
      location="bottom"
      buttonText="THATS OKEY"
      cookieName="CookieConsent Service"
      onDecline={() => {
        alert("nay!");
      }}
    >
      <div className={cx("Heading__small Bold")}>We use cookies</div>
      <small>
        We use cookies and similar technologies to give you a personalised
        shopping experience, personalised advertising and to analyse our web
        traffic.
      </small>
      <br />
      <small>
        <b>
          By entering this website you acknowledge and represent that you have
          reviewed and agree to our{" "}
        </b>
        <Link href="/service/terms" target="_blank">
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link href="/service/privacy" target="_blank">
          Privacy Policy﻿﻿
        </Link>
      </small>
    </CookieConsent>
  );
};

export default ServicePopup;
