import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import { Icon, UserMenu } from "components";
import Link from "next/link";

const TopMenu = ({ label, className, children, ...other }) => (
  <div className={cx("topMenu", s.topMenu)}>
    <div className={s.logoHolder}>
      <picture>
        <source media="(max-width: 768px)" srcSet="/img/cc_symbol-black.png" />
        <source media="(min-width: 769px)" srcSet="/img/cc_logo-dark.png" />
        <img src="/img/cc_symbol-black.png" alt="" />
      </picture>
    </div>
    <UserMenu />
  </div>
);

export default TopMenu;
