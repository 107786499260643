import Link from "next/link";
import * as cx from "classnames/bind";
import s from "styles/Service.module.scss";
import { useTranslation } from "next-i18next";

export default function ServiceMenu({ title }) {
  const { t } = useTranslation();

  return (
    <div className={cx(s.PolicyHeader)}>
      <h1 className="Heading__main">{title}</h1>
      <ul className="flex flex-wrap space-between">
        <li>
          <Link href="/service/contact">{t("Contact")}</Link>
        </li>
        <li>
          <Link href="/service/faq">{t("FAQ")}</Link>
        </li>
        <li>
          <Link href="/service/shipping">{t("Shipping")}</Link>
        </li>
        <li>
          <Link href="/service/returns">{t("Returns")}</Link>
        </li>
        <li>
          <Link href="/service/cookies">{t("Cookies")}</Link>
        </li>
        <li>
          <Link href="/service/terms">{t("Terms")}</Link>
        </li>
        <li>
          <Link href="/service/privacy">{t("Privacy")}</Link>
        </li>
      </ul>
    </div>
  );
}
